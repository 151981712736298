import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useHistory} from 'react-router-dom';

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
import LocalTaxiIcon from "@material-ui/icons/LocalTaxiOutlined";
import PermIdentity from "@material-ui/icons/PermIdentity";
import ScheduleIcon from '@material-ui/icons/Schedule';
import PhoneIcon from '@material-ui/icons/Phone';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PetsIcon from '@material-ui/icons/Pets';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import PeopleIcon from '@material-ui/icons/People';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PaymentIcon from '@material-ui/icons/Payment';
import RoomIcon from '@material-ui/icons/Room';
import AccessibleIcon from '@material-ui/icons/Accessible';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import Button from "../../components/CustomButtons/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesSweetAlert from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import AudioPlayer from "material-ui-audio-player";
import {CalendarToday, CalendarViewDay} from "@material-ui/icons";

const useStyles = makeStyles(styles);
const useStylesCustomSelect = makeStyles(customSelectStyle)
const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const classNames = require('classnames');

const useStylesAudioPlayer = makeStyles((theme) => {
    return {
        root: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            margin: "10px 20px 10px 0",
        },
        loopIcon: {
            color: '#3f51b5',
            '&.selected': {
                color: '#0921a9',
            },
            '&:hover': {
                color: '#7986cb',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        playIcon: {
            color: '#f50057',
            '&:hover': {
                color: '#ff4081',
            },
        },
        replayIcon: {
            color: '#e6e600',
        },
        pauseIcon: {
            color: '#0099ff',
        },
        volumeIcon: {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        volumeSlider: {
            color: 'black',
        },
        progressTime: {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        mainSlider: {
            color: '#3f51b5',
            '& .MuiSlider-rail': {
                color: '#7986cb',
            },
            '& .MuiSlider-track': {
                color: '#3f51b5',
            },
            '& .MuiSlider-thumb': {
                color: '#303f9f',
            },
        }
    };
});

export default function RideDetails() {
    const classesCustomSelect = useStylesCustomSelect()
    const classesSweetAlert = useStylesSweetAlert()
    const selectClasses = classNames(classesCustomSelect.autocompleteTextFieldNoPaddingTop)

    const [isLoading, setIsLoading] = useState(true)
    const [ride, setRide] = useState({})
    const [drivers, setDrivers] = useState([])
    const [alert, setAlert] = useState(null);
    const {id} = useParams()
    const [dispatchedInsideGroupement, setDispatchedInsideGroupement] = useState(0)
    const [createdBy, setCreatedBy] = useState({})
    const history = useHistory();

    const iconsStyle = {
        width: 40,
        height: 40,
        verticalAlign: "middle",
        padding: 3
    }

    const iconsStyleDeparturePin = {
        width: 40,
        height: 40,
        verticalAlign: "middle",
        padding: 3,
        color: "#51953D"
    }

    const iconsStyleArrivalPin = {
        width: 40,
        height: 40,
        verticalAlign: "middle",
        padding: 3,
        color: "#e33b35"
    }

    const rightIconsStyle = {
        width: 40,
        height: 40,
        verticalAlign: "middle",
        padding: 3,
        marginLeft: 20
    }

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

    useEffect(() => {
        fetch(`https://api.taximoove.fr/rides/${id}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(rideJson => {
                console.log(rideJson)
                setRide(rideJson)
                setCreatedBy(rideJson.createdBy !== undefined && rideJson.createdBy !== null ? rideJson.createdBy : rideJson.userCreating)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        fetch(`https://api.taximoove.fr/drivers`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(driversJson => {
                console.log(driversJson)
                setDrivers(driversJson)
            })
            .catch(error => {
                console.log("Error : " + error)
            })
    }, [])

    const getPaymentTypeLabel = (paymentType) => {
        switch(paymentType) {
            case "CASH":
                return "Espèces"
            case "BANK_CARD":
                return "Carte bancaire"
            case "AMEX":
                return "American Express"
            case "CHECK":
                return "Chèque"
            case "WIRE_TRANSFER":
                return "Virement"
            case "COURSE_EN_COMPTE":
                return "Course en compte"
            case "FORFAIT_COURSE_PLAFONNEE":
                return "Forfait course plafonnée"
            case "CPAM_FULL":
                return "CPAM 100%"
            case "CPAM_65_35":
                return "CPAM 65-35%"
            default:
                return "Indifférent"
        }
    }

    const getVehicleTypeLabel = (vehicleType) => {
        switch(vehicleType) {
            case "BREAK":
                return "Break"
            case "SEDAN":
                return "Berline"
            case "MONOSPACE":
                return "Monospace"
            case "VAN":
                return "Van"
            default:
                return "Indifférent"
        }
    }

    const getRightPhoneNumberForClient = (client) => {
        if(client.category.code === 'PRIVATE') {
            return client.mobilePhoneNumber
        }

        return client.homePhoneNumber !== "" ? client.homePhoneNumber : client.mobilePhoneNumber
    }

    const call = (phoneNumber) => {

        if(phoneNumber.startsWith("01")) {
            phoneNumber = phoneNumber.replace("01", "331")
        } else if(phoneNumber.startsWith("02")) {
            phoneNumber = phoneNumber.replace("02", "332")
        } else if(phoneNumber.startsWith("03")) {
            phoneNumber = phoneNumber.replace("03", "333")
        } else if(phoneNumber.startsWith("04")) {
            phoneNumber = phoneNumber.replace("04", "334")
        } else if(phoneNumber.startsWith("05")) {
            phoneNumber = phoneNumber.replace("05", "335")
        } else if(phoneNumber.startsWith("06")) {
            phoneNumber = phoneNumber.replace("06", "336")
        } else if(phoneNumber.startsWith("07")) {
            phoneNumber = phoneNumber.replace("07", "337")
        } else if(phoneNumber.startsWith("08")) {
            phoneNumber = phoneNumber.replace("08", "338")
        } else if(phoneNumber.startsWith("09")) {
            phoneNumber = phoneNumber.replace("09", "339")
        }

        window['_jorel-callPhoneNumber'](phoneNumber)
        console.log(phoneNumber)
    }

    const sendInDispatch = () => {
        fetch(`https://api.taximoove.fr/dispatch?rideId=${ride.id}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'PUT',
            headers: headers
        })
            .then(response => {
                if(response.status === 200) {
                    sendRideInDispatchSuccess("La course a bien été envoyée en distribution. Cliquez sur INFORMATIONS DE DISTRIBUTION pour voir le résultat.")
                } else {
                    dispatchError("Il y a eu une erreur lors de l'envoi de la course en distribution. Veuillez réessayer.")
                }
            })
            .catch(error => console.log("Error : " + error))
    }

    const dispatchInsideGroupement = (event, driverFullName) => {
        if(event.target.value === 0 && ride.dispatchedTo !== undefined) {
            // Remove ride from whoever has it
            fetch(`https://api.taximoove.fr/rides/${ride.id}/remove-affected-driver`, {
                mode: 'cors',
                credentials: 'include',
                method: 'PUT',
                headers: headers
            })
                .then(response => response.json())
                .then(jsonResponse => {
                    console.log(jsonResponse)
                    if(jsonResponse.code !== undefined && jsonResponse.code !== 200) {
                        dispatchError(jsonResponse.message)
                    } else {
                        setRide(jsonResponse)
                        setDispatchedInsideGroupement(event.target.value)
                        dispatchSuccess("La course a bien été retirée à " + ride.dispatchedTo.firstName + " " + ride.dispatchedTo.lastName)
                    }
                })
                .catch(error => console.log("Error : " + error))
        } else {
            fetch(`https://api.taximoove.fr/rides/${ride.id}/drivers/${event.target.value}`, {
                mode: 'cors',
                credentials: 'include',
                method: 'POST',
                headers: headers
            })
                .then(response => response.json())
                .then(jsonResponse => {
                    console.log(jsonResponse)
                    if(jsonResponse.code !== undefined && jsonResponse.code !== 200) {
                        dispatchError(jsonResponse.message)
                    } else {
                        setRide(jsonResponse)
                        setDispatchedInsideGroupement(event.target.value)
                        dispatchSuccess("La course a bien été affectée à " + driverFullName)
                    }
                })
                .catch(error => console.log("Error : " + error))
        }
    }

    const hideAlert = () => {
        setAlert(null)
    }

    const sendRideInDispatchSuccess = (message) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Félicitations !"
                onConfirm={() => {
                    hideAlert()
                    dispatchDetail()
                }}
                onCancel={() => {
                    hideAlert()
                    dispatchDetail()
                }}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
            >
                {message}
            </SweetAlert>
        );
    };

    const dispatchSuccess = (message) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Félicitations !"
                onConfirm={() => {
                    hideAlert()
                }}
                onCancel={() => {
                    hideAlert()
                }}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
            >
                {message}
            </SweetAlert>
        );
    };

    const dispatchError = (errorMessage) => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Erreur !"
                onConfirm={() => {
                    hideAlert()
                }}
                onCancel={() => {
                    hideAlert()
                }}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
            >
                {errorMessage}
            </SweetAlert>
        )
    }

    const dispatchInsideGroupementConfirmMessage = (event) => {
        let driverFullName = ""
        drivers.forEach(driver => {
            if(driver.id === event.target.value) {
                driverFullName = driver.firstName + " " + driver.lastName
            }
        })
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Êtes-vous certain(e) ?"
                onConfirm={() => {
                    hideAlert()
                    dispatchInsideGroupement(event, driverFullName)
                }}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
                cancelBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
                confirmBtnText="Oui"
                cancelBtnText="Non"
                showCancel
            >
                {event.target.value === 0 && ride.dispatchedTo !== undefined ? "Êtes-vous certain(e) de vouloir retirer la course à " + ride.dispatchedTo.firstName + " " + ride.dispatchedTo.lastName : "Êtes-vous certain(e) de vouloir affecter la course à " + driverFullName }
            </SweetAlert>
        );
    };

    const cancel = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Action annulée"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
            >
                Votre course n'a pas été envoyée en distribution
            </SweetAlert>
        );
    }

    const sendInDispatchConfirmMessage = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Êtes-vous sûr(e) ?"
                onConfirm={sendInDispatch}
                onCancel={cancel}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
                cancelBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
                confirmBtnText="Oui"
                cancelBtnText="Non"
                showCancel
            >
                Je veux envoyer la course en distribution !
            </SweetAlert>
        );

    }

    const dispatchDetail = () => {
        let path = "/courses/" + ride.id + "/distribution";
        history.push(path);
    }

    const classes = useStyles();
    const {rideNumber, fromAddress, toAddress, departingAt, remarks, nbAdults, nbChildren, nbLugages, pets, handicapedClient, vehicleType, paymentType, client} = ride
    return (
        <div>
            {alert}
            {isLoading ? <div></div> :
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color="rose">
                                    <LocalTaxiIcon htmlColor={"#ffffff"}/>
                                </CardIcon>

                                <h4 className={classes.cardIconTitle}>
                                    <span style={{fontWeight: "bold", verticalAlign: "middle"}}>
                                        Course {rideNumber}
                                    </span>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <GridItem xs={12} sm={12} md={12} >
                                            <div>
                                                <ScheduleIcon style={iconsStyle}/>
                                                <span style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10}}>{departingAt}</span>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: 10}}>
                                                <RoomIcon style={iconsStyleDeparturePin}/>
                                                <span style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10}}>{fromAddress}</span>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: 10}}>
                                                <RoomIcon style={iconsStyleArrivalPin}/>
                                                <span style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10}}>{toAddress}</span>
                                            </div>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: 10}}>
                                                <PermIdentity style={iconsStyle}/>
                                                <span style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10}}>{client.firstName + " " + client.lastName}</span>
                                            </div>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: 10}}>
                                                <PhoneIcon style={iconsStyle}/>
                                                <span onClick={() => call(getRightPhoneNumberForClient(client))} style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10, fontWeight: "400"}}>{getRightPhoneNumberForClient(client)}</span>
                                            </div>
                                        </GridItem>

                                        {remarks !== "" && remarks !== null && remarks !== undefined ?
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div style={{marginTop: 10}}>
                                                    <AnnouncementIcon style={iconsStyle}/>
                                                    <span style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10}}>{remarks}</span>
                                                </div>
                                            </GridItem> : ""
                                        }
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={5}>
                                        <GridContainer>
                                            {drivers === undefined || drivers.length === 0 ?
                                                <div>
                                                    <GridItem xs={12} sm={12} md={12} >
                                                        <div style={{float: "right"}}>
                                                            <PeopleIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{nbAdults}</span>

                                                            <ChildCareIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{nbChildren}</span>

                                                            <CardTravelIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{nbLugages}</span>

                                                            <PetsIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{pets ? "Oui" : "Non"}</span>

                                                            <AccessibleIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{handicapedClient ? "Oui" : "Non"}</span>
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12} >
                                                        <div style={{float: "right"}}>
                                                            <DriveEtaIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{getVehicleTypeLabel(vehicleType)}</span>

                                                            <PaymentIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{getPaymentTypeLabel(paymentType)}</span>
                                                        </div>
                                                    </GridItem>
                                                </div>
                                                :
                                                <div>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <FormControl fullWidth
                                                                     className={selectClasses}>
                                                            <Select
                                                                labelId="dispatchInsideGroupement"
                                                                id="dispatchInsideGroupement"
                                                                name="dispatchInsideGroupement"
                                                                onChange={dispatchInsideGroupementConfirmMessage}
                                                                value={ride.dispatchedTo !== undefined && ride.dispatchedTo != null && ride.dispatchedTo.groupement !== undefined
                                                                && ride.dispatchedTo.groupement !== null && ride.dispatchedTo.groupement.id.toString() === localStorage.getItem("groupementId") ? ride.dispatchedTo.id : 0}
                                                            >
                                                                <MenuItem value={0}>Non affectée dans le groupement</MenuItem>
                                                                {
                                                                    drivers.map(driver => {
                                                                        return <MenuItem key={driver.id} value={driver.id}>{driver.firstName + " " + driver.lastName}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>Affecter la course à</FormHelperText>
                                                        </FormControl>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12} >
                                                        <div style={{float: "right"}}>
                                                            <PeopleIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{nbAdults}</span>

                                                            <ChildCareIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{nbChildren}</span>

                                                            <CardTravelIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{nbLugages}</span>

                                                            <PetsIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{pets ? "Oui" : "Non"}</span>

                                                            <AccessibleIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{handicapedClient ? "Oui" : "Non"}</span>
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12} >
                                                        <div style={{float: "right"}}>
                                                            <DriveEtaIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{getVehicleTypeLabel(vehicleType)}</span>

                                                            <PaymentIcon style={rightIconsStyle}/>
                                                            <span style={{fontSize: 20, verticalAlign: "middle"}}>{getPaymentTypeLabel(paymentType)}</span>
                                                        </div>
                                                    </GridItem>
                                                </div>
                                            }
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                                <Clearfix/>

                                <br />
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={7}>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={5}>
                                            <Button color="rose" className={classes.updateProfileButton} onClick={dispatchDetail}>
                                                Informations de distribution
                                            </Button>
                                            {
                                                ride.status !== 'DELETED' && ride.status !== 'CLIENT_DROPPED_OFF' ?
                                                <div>
                                                    <Button color="rose" className={classes.updateProfileButton} onClick={sendInDispatchConfirmMessage}>
                                                        Envoyer en distribution
                                                    </Button>
                                                    <Button color="rose" className={classes.updateProfileButton} onClick={() => {
                                                        let path = `/courses/${ride.id}/modifier`
                                                        history.push(path)
                                                    }}>
                                                    Modifier
                                                    </Button>
                                                </div>: "" }
                                        </GridItem>

                                    </GridContainer>
                            </CardBody>
                        </Card>

                        <Card>
                                <CardHeader>
                                    <CardIcon color="rose">
                                        <PermIdentity htmlColor={"#ffffff"}/>
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Course créée par
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div style={{marginTop: 10}}>
                                                    <PermIdentity style={iconsStyle}/>
                                                    <span style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10}}>{createdBy.firstName + ' ' + createdBy.lastName}</span>
                                                </div>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={12}>
                                                <div style={{marginTop: 10}}>
                                                    <ScheduleIcon style={iconsStyle}/>
                                                    <span style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10}}>{ride.createdAt}</span>
                                                </div>
                                            </GridItem>

                                            {createdBy.phoneNumber !== undefined && createdBy.phoneNumber !== null ?
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div style={{marginTop: 10}}>
                                                    <PhoneIcon style={iconsStyle}/>
                                                    <span onClick={() => call(createdBy.phoneNumber)} style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10, fontWeight: "400"}}>{createdBy.phoneNumber}</span>
                                                </div>
                                            </GridItem> : "" }
                                        </GridItem>
                                    </GridContainer>
                                    <Clearfix/>
                                </CardBody>
                            </Card>

                        {ride.dispatchedTo !== undefined && ride.dispatchedTo !== null ?
                        <Card>
                            <CardHeader>
                                <CardIcon color="rose">
                                    <PermIdentity htmlColor={"#ffffff"}/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Course affectée à
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: 10}}>
                                                <PermIdentity style={iconsStyle}/>
                                                <span style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10}}>{ride.dispatchedTo.firstName + ' ' + ride.dispatchedTo.lastName}</span>
                                            </div>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: 10}}>
                                                <PhoneIcon style={iconsStyle}/>
                                                <span onClick={() => call(ride.dispatchedTo.phoneNumber)} style={{fontSize: 20, verticalAlign: "middle", marginLeft: 10, fontWeight: "400"}}>{ride.dispatchedTo.phoneNumber}</span>
                                            </div>
                                        </GridItem>
                                    </GridItem>
                                </GridContainer>
                                <Clearfix/>
                            </CardBody>
                        </Card> : "" }

                        {ride.call !== undefined && ride.call !== null ?
                            <Card>
                                <CardHeader>
                                    <CardIcon color="rose">
                                        <PermIdentity htmlColor={"#ffffff"}/>
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Appel
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <AudioPlayer
                                                elevation={1}
                                                width="100%"
                                                variation="default"
                                                spacing={0}
                                                download={false}
                                                autoplay={false}
                                                order="standart"
                                                preload="auto"
                                                loop={false}
                                                volume={false}
                                                src={ride.call.fileUrl}
                                                useStyles={useStylesAudioPlayer}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <Clearfix/>
                                </CardBody>
                            </Card> : "" }
                    </GridItem>
                </GridContainer>
            }
        </div>
    )
}