import React, {useEffect, useState} from "react";

// @material-ui/core components
import RidesComponent from "../../components/Rides/Rides";
import {useHistory} from "react-router-dom";
import Utils from "../../components/Utils/Utils";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import GridContainer from "../../components/Grid/GridContainer";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/icons
// core components

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(alertStyles);

export default function RidesToBeInvoiced() {
    const classes = useStyles()
    const [drivers, setDrivers] = useState([])
    const alertClasses = useAlertStyles();
    const [rides, setRides] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [totalElements, setTotalElements] = useState(0)

    const history = useHistory();

    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())

    const initialDate = Utils.getInstance().convertDateToString(new Date())
    const [fromDateStr, setFromDateStr] = useState(initialDate)
    const [toDateStr, setToDateStr] = useState(initialDate)
    const [searchValue, setSearchValue] = useState("")
    const [alert, setAlert] = useState(null);
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedDriverIdInSearch, setSelectedDriverIdInSearch] = useState(0)

    let headers = Utils.getInstance().getHeaders()
    const fetchRidesToBeInvoiced = () => {
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/rides/to-be-invoiced?size=${pageSize}&page=${currentPage}&type=all`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                setTotalElements(jsonResult.totalElements)

                const currentRides = []
                jsonResult.content.forEach(ride => {
                    let rideDispatchedTo = ""

                    if(ride.dispatchedTo !== undefined && ride.dispatchedTo !== null) {
                        rideDispatchedTo = ride.dispatchedTo.firstName + " " + ride.dispatchedTo.lastName

                        if(ride.dispatchedTo.groupement !== undefined && ride.dispatchedTo.groupement != null && ride.dispatchedTo.groupement.id.toString() === localStorage.getItem("groupementId")) {
                            rideDispatchedTo = rideDispatchedTo + " (" + ride.dispatchedTo.numberInGroupement + ")"
                        }
                    }

                    const currentRide = {
                        ...ride,
                        clientFullName: ride.client.firstName + " " + ride.client.lastName,
                        rideStatus: Utils.getInstance().getRideStatus(ride.status),
                        rideDriver: rideDispatchedTo
                    }

                    currentRides.push(currentRide)
                })
                setRides(currentRides)
                setIsLoading(false)
            })
            .catch(error => {
                errorAlert(error)
                setIsLoading(false)
            })
    }

    const fetchGroupementDrivers = () => {
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/drivers`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                setDrivers(jsonResult)
                setIsLoading(false)
            })
            .catch(error => {
                errorAlert(error)
                setIsLoading(false)
            })
    }

    const filterRides = (event) => {
        if(event !== undefined && event !== null) {
            event.preventDefault()
        }

        let url = `https://api.taximoove.fr/rides/to-be-invoiced?size=${pageSize}&page=${currentPage}&type=all`

        if(fromDateStr !== toDateStr) {
            url = url + `fromDate=${fromDateStr}&toDate=${toDateStr}`
        }

        if(searchValue !== undefined && searchValue.trim() !== "") {
            url = url + `filter=${searchValue}`
        }

        if(selectedDriverIdInSearch !== 0) {
            url = url + `&driverId=${selectedDriverIdInSearch}`
        }

        console.log(url + " / " + fromDateStr + " - " + toDateStr)
        setIsLoading(true)
        fetch(url, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                setTotalElements(jsonResult.totalElements)

                const currentRides = []
                jsonResult.content.forEach(ride => {
                    let rideDispatchedTo = ""

                    if(ride.dispatchedTo !== undefined && ride.dispatchedTo !== null) {
                        rideDispatchedTo = ride.dispatchedTo.firstName + " " + ride.dispatchedTo.lastName

                        if(ride.dispatchedTo.groupement !== undefined && ride.dispatchedTo.groupement != null && ride.dispatchedTo.groupement.id.toString() === localStorage.getItem("groupementId")) {
                            rideDispatchedTo = rideDispatchedTo + " (" + ride.dispatchedTo.numberInGroupement + ")"
                        }
                    }

                    const currentRide = {
                        ...ride,
                        clientFullName: ride.client.firstName + " " + ride.client.lastName,
                        rideStatus: Utils.getInstance().getRideStatus(ride.status),
                        rideDriver: rideDispatchedTo
                    }

                    currentRides.push(currentRide)
                })
                setRides(currentRides)
                setIsLoading(false)
            })
            .catch(error => {
                errorAlert(error)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchGroupementDrivers()
    }, [])

    useEffect(() => {
        fetchRidesToBeInvoiced()
    }, [pageSize, currentPage])

    const handleRowsSelection = (rows) => {
        setSelectedRows(rows)
    }

    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value)
    }

    const handlePageChange = (event, newPageNumber) => {
        setCurrentPage(newPageNumber)
        fetchRidesToBeInvoiced()
    }

    const handleFromDateChange = (date, value) => {
        setFromDate(date)
        setFromDateStr(value)
    }

    const handleToDateChange = (date, value) => {
        setToDate(date)
        setToDateStr(value)
    }

    const handleSearch = (searchText) => {
        setSearchValue(searchText)
    }

    const createInvoice = () => {
        let ridesToBeInvoiced = selectedRows
        let clientIds = new Set()
        ridesToBeInvoiced.forEach(rideToBeInvoiced => {
            clientIds.add(rideToBeInvoiced.client.id)
        })

        if(clientIds.size !== 1) {
            errorAlert("Vous ne pouvez pas générer de facture pour des clients différents. Veuilez uniquement sélectionner des courses du même client.")
        } else {
            let priceWithoutTaxTotal = 0
            let priceWithTaxTotal = 0
            let invoiceItems = []
            ridesToBeInvoiced.forEach(rideToBeInvoiced => {
                invoiceItems.push({
                    priceWithoutTax: rideToBeInvoiced.priceWithoutTax,
                    priceWithTax: rideToBeInvoiced.priceWithTax,
                    description: rideToBeInvoiced.fromAddress + " - " + rideToBeInvoiced.toAddress
                })

                priceWithoutTaxTotal = priceWithoutTaxTotal + rideToBeInvoiced.priceWithoutTax
                priceWithTaxTotal = priceWithTaxTotal + rideToBeInvoiced.priceWithTax
            })

            let invoice = {
                priceWithoutTax: priceWithoutTaxTotal,
                priceWithTax: priceWithTaxTotal,
                rides: ridesToBeInvoiced,
                invoiceItems: invoiceItems,
                groupement: {
                    id: localStorage.getItem("groupementId")
                },
                clientInvoiced: {
                    id: ridesToBeInvoiced[0].client.id
                }
            }


            fetch(`https://api.taximoove.fr/invoices`, {
                mode: 'cors',
                credentials: 'include',
                method: 'POST',
                headers: headers,
                body: JSON.stringify(invoice)
            })
                .then(response => {
                    let returnValue = {}
                    if(response.status !== 200) {
                        errorAlert("Il y a eu un problème lors de la création de la facture. Veuillez réessayer ou nous contacter dans les plus brefs délais.")
                    } else {
                        returnValue = response
                    }

                    return returnValue.json()
                })
                .then(json => {
                    console.log(json)
                    if(json.url !== undefined && json.url !== null) {
                        createInvoiceSuccess(json.url)
                    }
                })
                .catch(error => {
                    errorAlert(error)
                })
        }
    }

    const alertCreateInvoice = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Êtes-vous sûr(e) ?"
                onConfirm={() => createInvoice()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Oui, je veux génerer une facture !"
                cancelBtnText="Annuler"
                showCancel
            >
                En générant cette facture, vous ne pourrez plus revenir en arrière.
            </SweetAlert>
        );
    }

    const errorAlert = (errorMessage) => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Erreur !"
                onConfirm={() => {
                    hideAlert()
                }}
                onCancel={() => {
                    hideAlert()
                }}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            >
                {errorMessage}
            </SweetAlert>
        )
    }

    const createInvoiceSuccess = (url) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Félicitations !"
                onConfirm={() => {
                    hideAlertAndReloadRides()
                    window.open(url, "_blank")
                }}
                onCancel={() => {
                    hideAlertAndReloadRides()
                }}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            >
                La facture a correctement été générée
            </SweetAlert>
        );
    }

    const hideAlertAndReloadRides = () => {
        hideAlert()
        if(searchValue !== "") {
            filterRides()
        } else {
            fetchRidesToBeInvoiced()
        }
    }

    const hideAlert = () => {
        setAlert(null);
    }

    return (
        isLoading ? <div></div> :
        <div>
            {alert}
            <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <Button color="rose" className={classes.updateProfileButton} onClick={alertCreateInvoice}>
                        Générer facture
                    </Button>
                </GridItem>
            </GridContainer>
            <RidesComponent
                history={history}
                drivers={drivers}
                selectedDriverIdInSearch={selectedDriverIdInSearch}
                onDriverSelectedChange={(event) => {
                    setSelectedDriverIdInSearch(event.target.value)
                }}
                rides={rides}
                pageSize={pageSize}
                totalElements={totalElements}
                currentPage={currentPage}
                searchValue={searchValue}
                fromDate={fromDate}
                toDate={toDate}
                actionDelete={false}
                actionSelect={true}
                handlePageChange={(event, newPageNumber) => handlePageChange(event, newPageNumber)}
                handleRowsPerPageChange={(event) => handleRowsPerPageChange(event)}
                onSearchChange={(newValue) => handleSearch(newValue)}
                cancelSearch={() => handleSearch("")}
                handleFromDateChange={(date, value) => handleFromDateChange(date, value)}
                handleToDateChange={(date, value) => handleToDateChange(date, value)}
                filterRides={(event) => filterRides(event)}
                handleRowsSelection={(rows) => handleRowsSelection(rows)}
            />
        </div>
    )
}


