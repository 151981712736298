import React, {useEffect, useState} from "react";

// @material-ui/core components
import MaterialTable from 'material-table'
import {TablePagination} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalTaxiIcon from "@material-ui/icons/LocalTaxi";
import {AccessTime} from "@material-ui/icons";
import MarkAsPaidIcon from '@material-ui/icons/AccountBalance';

import Utils from "../../components/Utils/Utils";
import SweetAlert from "react-bootstrap-sweetalert";
import {makeStyles} from "@material-ui/core/styles";
import alertStyles from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
// @material-ui/icons
// core components
const useAlertStyles = makeStyles(alertStyles);


export default function Invoices() {
    const alertClasses = useAlertStyles();

    const [alert, setAlert] = useState(null)
    const [invoices, setInvoices] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [totalElements, setTotalElements] = useState(0)

    useEffect(() => {
        fetchAllInvoices()
    }, [pageSize, currentPage])

    let headers = Utils.getInstance().getHeaders()
    const fetchAllInvoices = () => {
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/invoices?size=${pageSize}&page=${currentPage}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                console.log(jsonResult)
                setTotalElements(jsonResult.totalElements)

                var invoicesToBeSet = []
                jsonResult.content.forEach(invoice => {
                    invoice.invoicePrice = `${invoice.priceWithTax} €`
                    if(invoice.clientInvoiced.firstName !== undefined && invoice.clientInvoiced.firstName !== null) {
                        invoice.clientFullName = invoice.clientInvoiced.firstName + " " + invoice.clientInvoiced.lastName
                    } else {
                        invoice.clientFullName = invoice.clientInvoiced.lastName
                    }
                    invoicesToBeSet.push(invoice)
                })

                setInvoices(invoicesToBeSet)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value)
    }

    const handlePageChange = (event, newPageNumber) => {
        setCurrentPage(newPageNumber)
    }

    const alertMarkInvoiceAsPaid = (invoiceId) => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Êtes-vous sûr(e) ?"
                onConfirm={() => markInvoiceAsPaid(invoiceId)}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Oui, je veux valider le paiement de cette facture !"
                cancelBtnText="Annuler"
                showCancel
            >
                En validant le paiement, le statut de la facture ne pourra plus être modifié.
            </SweetAlert>
        );
    }

    const markInvoiceAsPaid = (invoiceId) => {
        fetch(`https://api.taximoove.fr/invoices/${invoiceId}?status=PAID`, {
            mode: 'cors',
            credentials: 'include',
            method: 'PUT',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                if(jsonResult.id !== undefined && jsonResult.id !== null) {
                    markInvoiceAsPaidSuccess()
                } else {
                    errorAlert(jsonResult.message)
                }
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const errorAlert = (errorMessage) => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Erreur !"
                onConfirm={() => {
                    hideAlert()
                }}
                onCancel={() => {
                    hideAlert()
                }}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            >
                {errorMessage}
            </SweetAlert>
        )
    }

    const markInvoiceAsPaidSuccess = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Félicitations !"
                onConfirm={() => {
                    hideAlert()
                    fetchAllInvoices()
                }}
                onCancel={() => {
                    hideAlert()
                    fetchAllInvoices()
                }}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            >
                La facture a été marquée comme payée
            </SweetAlert>
        );
    }

    const hideAlert = () => {
        setAlert(null);
    }

    const columns = [
        { title: 'Facture n°', field: 'number', searchable: false  },
        { title: 'Facturée le', field: 'invoicedAt', searchable: false  },
        { title: 'Client facturé', field: 'clientFullName' , searchable: false },
        { title: 'Statut', field: 'status' , searchable: false },
        { title: 'Prix', field: 'invoicePrice' , searchable: false },
    ];

    return (
        isLoading ? <div></div> :
        <div>
            {alert}
            <MaterialTable columns={columns}
                           data={invoices}
                           title='Factures'
                           options={{
                               search: false,
                               pageSize: pageSize,
                               sorting: false,
                               actionsColumnIndex: -1
                           }}
                           actions={[
                               rowData => ({
                                   icon: VisibilityIcon,
                                   tooltip: 'Voir la facture',
                                   onClick: (event, selectedRow) => {
                                       window.open(`${selectedRow.url}`, "_blank")
                                       event.stopPropagation()
                                   }
                               }),
                               rowData => ({
                                   icon: MarkAsPaidIcon,
                                   tooltip: 'Marquer comme payée',
                                   onClick: (event, selectedRow) => {
                                       alertMarkInvoiceAsPaid(selectedRow.id)
                                   },
                                   hidden: rowData.status === "PAID"
                               })
                           ]}
                           localization={{
                               pagination: {
                                   labelDisplayedRows: '{from}-{to} sur {count}',
                                   labelRowsSelect: "lignes"
                               },
                               toolbar: {
                                   nRowsSelected: '{0} lignes(s) sélectionnées'
                               },
                               body: {
                                   emptyDataSourceMessage: 'Il n\'y a pas de factures',
                                   filterRow: {
                                       filterTooltip: 'Filter'
                                   }
                               }
                           }}
                           components={{
                               Pagination: props => (
                                   <TablePagination
                                       {...props}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       rowsPerPage={pageSize}
                                       count={totalElements}
                                       page={currentPage}
                                       onChangePage={handlePageChange}
                                       onChangeRowsPerPage={handleRowsPerPageChange}
                                   />
                               )
                           }}
             />
        </div>
    )
}


