// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import AdminLayout from "./layouts/Admin";

const PrivateRoute = ({ component: Component, ...rest }) => {
    // Add your own authentication on the below line.
    const isLoggedIn = (localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null)
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Route path="/" component={AdminLayout} />
                ) : (
                    <Redirect to={{ pathname: '/connexion', state: { from: props.location } }} />
                )
            }
        />
    )
}

export default PrivateRoute