import React, {useEffect} from "react";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import SearchBar from 'material-ui-search-bar';
import {TablePagination} from '@material-ui/core';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
// @material-ui/icons
// core components
import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Clients() {
    const history = useHistory();

    const classes = useStyles();
    const [clients, setClients] = React.useState([])
    const [searchValue, setSearchValue] = React.useState("")
    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(true)
    const [totalElements, setTotalElements] = React.useState(0)
    const [alert, setAlert] = React.useState(null);

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

    const fetchAllClients = () => {
        console.log(currentPage)
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/clients/groupement/all?size=${pageSize}&page=${currentPage}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                console.log(jsonResult)
                setTotalElements(jsonResult.totalElements)

                const clientsToBeSet = jsonResult.content
                clientsToBeSet.forEach(client => {
                    if(client.gender === 'MADAM') {
                        client.gender = 'Mme'
                    } else {
                        client.gender = 'M.'
                    }

                    client.categoryName = getClientCategoryName(client.category.code)
                })
                setClients(clientsToBeSet)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const columns = [
        { title: 'Genre', field: 'gender', searchable: false  },
        { title: 'Nom', field: 'lastName' , searchable: false },
        { title: 'Prénom', field: 'firstName', searchable: false },
        { title: 'Société', field: 'company', searchable: false  },
        { title: 'Type', field: 'categoryName', searchable: false  }
    ];

    const getClientCategoryName = (clientCategoryCode) => {
        switch (clientCategoryCode) {
            case "PRIVATE":
                return "Particulier"
            case "PRO":
                return "Pro"
            case "COMPANY":
                return "Sociétés"
            case "HOTEL":
                return "Hôtels"
            case "CPAM":
                return "CPAM"
            case "VIP":
                return "VIP"
            case "BLACKLIST":
                return "Blacklist"
        }

        return ""
    }

    const handleSearch = (searchText) => {
        setSearchValue(searchText)
        if(searchText.length === 0) {
            fetchAllClients()
        } else if(searchText.length > 3) {
            fetch(`https://api.taximoove.fr/clients/groupement/filter/${searchText}`, {
                mode: 'cors',
                credentials: 'include',
                method: 'GET',
                headers: headers
            })
                .then(result => result.json())
                .then(jsonResult => {
                    const clentsToBeSet = []
                    jsonResult.forEach(client => {
                        if(client.gender === 'MADAM') {
                            client.gender = 'Mme'
                        } else {
                            client.gender = 'M.'
                        }

                        client.categoryName = getClientCategoryName(client.category.code)

                        clentsToBeSet.push(client)
                    })
                    setClients(clentsToBeSet)
                })
                .catch(error => console.log("Error : " + error))
        }
    }

    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value)
    }

    const handlePageChange = (event, newPageNumber) => {
        setCurrentPage(newPageNumber)
        fetchAllClients()
    }

    const hideAlertAndReloadClients = () => {
        hideAlert()
        if(searchValue !== "") {
            handleSearch(searchValue)
        } else {
            fetchAllClients()
        }
    }

    const deleteClientSuccess = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Félicitations !"
                onConfirm={() => {
                    hideAlertAndReloadClients()
                }}
                onCancel={() => {
                    hideAlertAndReloadClients()
                }}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Le client a correctement été supprimé
            </SweetAlert>
        );
    };


    const deleteClient = (id) => {
        fetch(`https://api.taximoove.fr/clients/${id}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'DELETE',
            headers: headers
        })
            .then(response => {
                if(response.status === 200) {
                    deleteClientSuccess()
                } else {
                    console.log("ERROR")
                }
            })
            .catch(error => {
                // TODO : Set error alert
                console.log("Error : " + error)
                setIsLoading(false)
                hideAlert()
            })
    }

    const cancelDelete = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Action annulée"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Votre client n'a pas été supprimé :)
            </SweetAlert>
        );
    }

    const hideAlert = () => {
        setAlert(null);
    };

    const alertDeleteClient = (id) => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Êtes-vous sûr(e) ?"
                onConfirm={() => deleteClient(id)}
                onCancel={() => cancelDelete()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Oui, je veux supprimer ce client !"
                cancelBtnText="Annuler"
                showCancel
            >
                En supprimant ce client, vous supprimez aussi l'ensemble de son historique de courses, d'appels et tout autre donnée liée à ce client.
            </SweetAlert>
        );

    }

    useEffect(() => {
        fetchAllClients()
    }, [pageSize, currentPage])

    return (
        isLoading ? <div></div> :
        <div>
            {alert}
            <SearchBar
                placeholder="Nom du client"
                value={searchValue}
                onChange={(newValue) => handleSearch(newValue)}
                onRequestSearch={() => handleSearch(searchValue)}
            />
            <br />
            <MaterialTable columns={columns}
                           data={clients}
                           title='Clients'
                           onSearchChange={(searchText) => handleSearch(searchText)}
                           onRowClick={(event, selectedRow) => {
                               history.push({pathname: `/clients/${selectedRow.id}`})
                               event.stopPropagation()
                           }}
                           options={{
                               search: false,
                               pageSize: pageSize,
                               sorting: false,
                               actionsColumnIndex: -1
                           }}
                           actions={[
                               {
                                   icon: LocalTaxiIcon,
                                   tooltip: 'Voir les courses de ce client',
                                   onClick: (event, selectedRow) => {
                                       history.push({pathname: `/clients/${selectedRow.id}/courses`})
                                       event.stopPropagation()
                                   }
                               },
                               {
                                   icon: 'delete',
                                   tooltip: 'Supprimer le client',
                                   onClick: (event, selectedRow) => {
                                       alertDeleteClient(selectedRow.id)
                                   }
                               }]}
                           localization={{
                               pagination: {
                                   labelDisplayedRows: '{from}-{to} sur {count}',
                                   labelRowsSelect: "lignes"
                               },
                               toolbar: {
                                   nRowsSelected: '{0} lignes(s) sélectionnées'
                               },
                               header: {
                                   actions: 'Actions'
                               },
                               body: {
                                   emptyDataSourceMessage: 'Il n\'y a pas de clients',
                                   filterRow: {
                                       filterTooltip: 'Filter'
                                   }
                               }
                           }}
                           components={{
                               Pagination: props => (
                                   <TablePagination
                                       {...props}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       rowsPerPage={pageSize}
                                       count={totalElements}
                                       page={currentPage}
                                       onChangePage={handlePageChange}
                                       onChangeRowsPerPage={handleRowsPerPageChange}
                                   />
                               )
                           }}
             />
        </div>
    )
}


