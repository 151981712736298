import React, {useEffect, useState} from "react";

// @material-ui/core components
import MaterialTable from 'material-table'
// @material-ui/icons
// core components
import 'moment/locale/fr';
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import {useParams} from "react-router-dom";
import Utils from "../../components/Utils/Utils";

export default function RideDispatchInfo() {
    const [events, setEvents] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const {id} = useParams()
    const [loadingCounter, setLoadingCounter] = useState(0)
    const [rideStatus, setRideStatus] = useState("")

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

    const fetchAllEvents = () => {
        setIsLoading(true)
        setLoadingCounter(1)
        fetch(`https://api.taximoove.fr/events?rideId=${id}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                console.log(jsonResult)
                setEvents(jsonResult)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const fetchRide = () => {
        setIsLoading(true)
        setLoadingCounter(1)
        fetch(`https://api.taximoove.fr/rides/${id}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                console.log(jsonResult)
                setRideStatus(Utils.getInstance().getRideStatus(jsonResult.status))
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchAllEvents()
            fetchRide()
        }, 1000)
        return () => {
            clearInterval(intervalId)
        }
    })

    const columns = [
        { title: 'Date', field: 'eventDate', searchable: false  },
        { title: 'Evénement', field: 'comment', searchable: false  }
    ];

    return (
        isLoading && loadingCounter === 0 ? <div></div> :
            <div>
                <MaterialTable
                        columns={columns}
                        data={events}
                        title={'Informations sur la distribution - ' + rideStatus}
                        options={{
                            search: false,
                            sorting: false,
                            paging: false
                        }}
                        localization={{
                           toolbar: {
                               nRowsSelected: '{0} lignes(s) sélectionnées'
                           },
                           header: {
                               actions: 'Actions'
                           },
                           body: {
                               emptyDataSourceMessage: 'Il n\'y a pas d\'évènements',
                           }
                        }}
                />
            </div>
    )
}


