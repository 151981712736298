import React, {Component} from 'react'

export class Utils extends Component {
    constructor(props) {
        super(props);
    }

    static getInstance() {
        return new Utils();
    }

    getRideStatus(status) {
        switch(status) {
            case "CREATED":
                return "Créée"
            case "SENT_IN_DISPATCH":
                return "Envoyée en distribution"
            case "FAILED_DISPATCH":
                return "Erreur de distribution"
            case "RETURNED_FROM_DISPATCH_WITHOUT_A_DRIVER":
                return "Distribuée mais aucun chauffeur associé"
            case "TO_BE_REDISPATCHED":
                return "A redisitribuer"
            case "ASSOCIATED_TO_A_DRIVER":
                return "Distribuée"
            case "DRIVER_APPROACHING":
                return "Chauffeur en approche"
            case "DRIVER_ONSITE":
                return "Chauffeur sur place"
            case "CLIENT_IN_THE_CAR":
                return "Client à bord"
            case "CLIENT_DROPPED_OFF":
                return "Terminée"
            case "DELETED":
                return "Supprimée"
        }

        return ""
    }

    convertDateToString(date) {
        let dateStr = ""
        if(date.getDate() > 9) {
            dateStr = date.getDate()
        } else {
            dateStr = "0" + date.getDate()
        }

        if((date.getMonth()+1) > 9) {
            dateStr = dateStr + "/" + (date.getMonth() + 1)
        } else {
            dateStr = dateStr + "/0" + (date.getMonth() + 1)
        }


        dateStr = dateStr + "/" + date.getFullYear()

        if(date.getHours() > 9){
            dateStr = dateStr + " " + date.getHours()
        } else {
            dateStr = dateStr + " 0" + date.getHours()
        }

        if(date.getMinutes() > 9){
            dateStr = dateStr + ":" + date.getMinutes()
        } else {
            dateStr = dateStr + ":0" + date.getMinutes()
        }

        return dateStr
    }

    getHeaders() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

        return headers
    }
}

export default Utils