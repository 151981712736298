import React, {useEffect, useState} from "react";

// @material-ui/core components
import MaterialTable from 'material-table'
import {TablePagination} from '@material-ui/core';
// @material-ui/icons
// core components
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import 'moment/locale/fr';
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import RidesComponent from "../../components/Rides/Rides";
import SearchBar from "material-ui-search-bar";
import Button from "../../components/CustomButtons/Button";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Utils from "../../components/Utils/Utils";
import {useHistory} from "react-router-dom";

const useAlertStyles = makeStyles(alertStyles);

export default function FinishedRides() {
    const alertClasses = useAlertStyles();
    const history = useHistory();

    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(true)
    const [totalElements, setTotalElements] = React.useState(0)
    const [rides, setRides] = React.useState([])
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())

    const initialDate = Utils.getInstance().convertDateToString(new Date())
    const [fromDateStr, setFromDateStr] = useState(initialDate)
    const [toDateStr, setToDateStr] = useState(initialDate)
    const [searchValue, setSearchValue] = React.useState("")
    const [alert, setAlert] = React.useState(null);

    let headers = Utils.getInstance().getHeaders()
    const fetchAllRides = () => {
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/rides?size=${pageSize}&page=${currentPage}&type=finished`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                console.log(jsonResult)
                setTotalElements(jsonResult.totalElements)

                const currentRides = []
                jsonResult.content.forEach(ride => {
                    let rideDispatchedTo = ""

                    if(ride.dispatchedTo !== undefined && ride.dispatchedTo !== null) {
                        rideDispatchedTo = ride.dispatchedTo.firstName + " " + ride.dispatchedTo.lastName

                        if(ride.dispatchedTo.groupement !== undefined && ride.dispatchedTo.groupement != null && ride.dispatchedTo.groupement.id.toString() === localStorage.getItem("groupementId")) {
                            rideDispatchedTo = rideDispatchedTo + " (" + ride.dispatchedTo.numberInGroupement + ")"
                        }
                    }

                    const currentRide = {
                        ...ride,
                        clientFullName: ride.client.firstName + " " + ride.client.lastName,
                        rideStatus: Utils.getInstance().getRideStatus(ride.status),
                        rideDriver: rideDispatchedTo
                    }

                    currentRides.push(currentRide)
                })
                setRides(currentRides)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value)
    }

    const handlePageChange = (event, newPageNumber) => {
        setCurrentPage(newPageNumber)
        fetchAllRides()
    }

    useEffect(() => {
        fetchAllRides()
    }, [pageSize, currentPage])

    const handleFromDateChange = (date, value) => {
        setFromDate(date)
        setFromDateStr(value)
    }

    const handleToDateChange = (date, value) => {
        setToDate(date)
        setToDateStr(value)
    }

    const handleSearch = (searchText) => {
        setSearchValue(searchText)
    }

    const filterRides = (event) => {
        if(event !== undefined && event !== null) {
            event.preventDefault()
        }

        let url = `https://api.taximoove.fr/rides?fromDate=${fromDateStr}&toDate=${toDateStr}&filter=${searchValue}&size=${pageSize}&page=${currentPage}&type=all`
        if(fromDateStr === toDateStr) {
            url = `https://api.taximoove.fr/rides?filter=${searchValue}&size=${pageSize}&page=${currentPage}&type=all`
        }

        console.log(url + " / " + fromDateStr + " - " + toDateStr)
        setIsLoading(true)
        fetch(url, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                setTotalElements(jsonResult.totalElements)

                const currentRides = []
                jsonResult.content.forEach(ride => {
                    const currentRide = {
                        ...ride,
                        clientFullName: ride.client.firstName + " " + ride.client.lastName,
                        rideStatus: Utils.getInstance().getRideStatus(ride.status)
                    }

                    currentRides.push(currentRide)
                })
                setRides(currentRides)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const hideAlert = () => {
        setAlert(null);
    };

    const deleteRide = (id) => {
        fetch(`https://api.taximoove.fr/rides/${id}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'DELETE',
            headers: headers
        })
            .then(response => {
                if(response.status === 200) {
                    deleteRideSuccess()
                } else {
                    console.log("ERROR")
                }
            })
            .catch(error => {
                // TODO : Set error alert
                console.log("Error : " + error)
                setIsLoading(false)
                hideAlert()
            })
    }

    const cancelDelete = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Action annulée"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            >
                Votre course n'a pas été supprimée :)
            </SweetAlert>
        );
    }

    const deleteRideSuccess = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Félicitations !"
                onConfirm={() => {
                    hideAlertAndReloadRides()
                }}
                onCancel={() => {
                    hideAlertAndReloadRides()
                }}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
            >
                La course a correctement été supprimée
            </SweetAlert>
        );
    };

    const hideAlertAndReloadRides = () => {
        hideAlert()
        if(searchValue !== "") {
            handleSearch(searchValue)
        } else {
            fetchAllRides()
        }
    }

    const alertDeleteRide = (id) => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Êtes-vous sûr(e) ?"
                onConfirm={() => deleteRide(id)}
                onCancel={() => cancelDelete()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
                cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
                confirmBtnText="Oui, je veux supprimer cette course !"
                cancelBtnText="Annuler"
                showCancel
            >
                En supprimant cette course, vous ne pourrez plus la retrouver.
            </SweetAlert>
        );

    }

    return (
        isLoading ? <div></div> :
            <div>
                {alert}
                <RidesComponent
                    history={history}
                    rides={rides}
                    pageSize={pageSize}
                    totalElements={totalElements}
                    currentPage={currentPage}
                    searchValue={searchValue}
                    fromDate={fromDate}
                    toDate={toDate}
                    actionDelete={true}
                    actionSelect={false}
                    onDeleteRide={(id) => {alertDeleteRide(id)}}
                    handlePageChange={(event, newPageNumber) => handlePageChange(event, newPageNumber)}
                    handleRowsPerPageChange={(event) => handleRowsPerPageChange(event)}
                    onSearchChange={(newValue) => handleSearch(newValue)}
                    cancelSearch={() => handleSearch("")}
                    handleFromDateChange={(date, value) => handleFromDateChange(date, value)}
                    handleToDateChange={(date, value) => handleToDateChange(date, value)}
                    filterRides={(event) => filterRides(event)}
                    handleRowsSelection={() => {}}
                />
            </div>
    )
}


