import React, {useEffect} from "react";
import {useHistory, useLocation} from 'react-router-dom';

// @material-ui/core components
import MaterialTable from 'material-table'
import {TablePagination} from '@material-ui/core';
import AudioPlayer from 'material-ui-audio-player';

// @material-ui/icons
// core components
import 'moment/locale/fr';
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import {AccessTime} from "@material-ui/icons";
import LocalTaxiIcon from "@material-ui/icons/LocalTaxi";

const useStylesAudioPlayer = makeStyles((theme) => {
    return {
        root: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            margin: "10px 20px 10px 0",
        },
        loopIcon: {
            color: '#3f51b5',
            '&.selected': {
                color: '#0921a9',
            },
            '&:hover': {
                color: '#7986cb',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        playIcon: {
            color: '#f50057',
            '&:hover': {
                color: '#ff4081',
            },
        },
        replayIcon: {
            color: '#e6e600',
        },
        pauseIcon: {
            color: '#0099ff',
        },
        volumeIcon: {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        volumeSlider: {
            color: 'black',
        },
        progressTime: {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        mainSlider: {
            color: '#3f51b5',
            '& .MuiSlider-rail': {
                color: '#7986cb',
            },
            '& .MuiSlider-track': {
                color: '#3f51b5',
            },
            '& .MuiSlider-thumb': {
                color: '#303f9f',
            },
        }
    };
});

export default function CallsToBeTreatedOrNot() {
    const history = useHistory();

    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(true)
    const [totalElements, setTotalElements] = React.useState(0)
    const [calls, setCalls] = React.useState([])
    const [toBeTreated, setToBeTreated] = React.useState(null)

    const location = useLocation()

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

    const fetchCalls = () => {
        if(toBeTreated === null) {
            return
        }
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/calls?toBeTreated=${toBeTreated}&size=${pageSize}&page=${currentPage}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                console.log(jsonResult)
                setTotalElements(jsonResult.totalElements)
                let callsToBeSet = []
                jsonResult.content.forEach(callJson => {
                    if(callJson.client !== undefined && callJson.client !== null) {
                        if(callJson.client.firstName !== undefined && callJson.client.firstName !== null
                            && callJson.client.lastName !== undefined && callJson.client.lastName !== null) {
                            callJson.caller = callJson.client.firstName + " " + callJson.client.lastName
                        } else if(callJson.client.firstName !== undefined && callJson.client.firstName !== null) {
                            callJson.caller = callJson.client.firstName
                        } else if(callJson.client.lastName !== undefined && callJson.client.lastName !== null) {
                            callJson.caller = callJson.client.lastName
                        }
                    } else {
                        callJson.caller = callJson.callerNumber
                    }

                    if(callJson.driver !== undefined && callJson.driver !== null) {
                        callJson.callee = callJson.driver.firstName + " " + callJson.driver.lastName + " (" + callJson.driver.numberInGroupement + ")"
                    } else if(callJson.operator !== undefined && callJson.operator != null) {
                        callJson.callee = callJson.operator.firstName + " " + callJson.operator.lastName
                    }

                    callsToBeSet.push(callJson)
                })
                setCalls(callsToBeSet)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value)
    }

    const handlePageChange = (event, newPageNumber) => {
        setCurrentPage(newPageNumber)
        fetchCalls()
    }

    useEffect(() => {
        if (location.pathname.toString().includes("a-traiter")) {
            setToBeTreated(true)
        } else {
            setToBeTreated(false)
        }
    }, [])

    useEffect(() => {
        console.log("TO BE TREATED " + toBeTreated)
        fetchCalls()
    }, [toBeTreated])

    const columns = [
        { title: 'Date de l\'appel', field: 'startedAt', searchable: false  },
        { title: 'Appelant', field: 'caller', searchable: false },
        { title: 'Appelé(e)', field: 'callee' , searchable: false },
        { title: 'Audio', field: 'fileUrl', searchable: false,
            render: rowData => <AudioPlayer
                elevation={1}
                width="100%"
                variation="default"
                spacing={0}
                download={false}
                autoplay={false}
                order="standart"
                preload="auto"
                loop={false}
                volume={false}
                useStyles={useStylesAudioPlayer}
                src={rowData.fileUrl}
            />
        }
    ];

    return (
        isLoading ? <div></div> :
            <div>
                {alert}
                {!toBeTreated ?
                <MaterialTable columns={columns}
                               data={calls}
                               title='Appels traités'
                               options={{
                                   search: false,
                                   pageSize: pageSize,
                                   sorting: false,
                                   actionsColumnIndex: -1
                               }}
                               actions={[
                                   {
                                       icon: LocalTaxiIcon,
                                       tooltip: 'Consulter la course',
                                       onClick: (event, selectedRow) => {
                                           console.log(selectedRow)
                                           history.push({
                                               pathname: `/courses/${selectedRow.ride.id}`,
                                               state: {
                                                   call: selectedRow
                                               }
                                           })
                                       }
                                   }
                               ]}
                               localization={{
                                   pagination: {
                                       labelDisplayedRows: '{from}-{to} sur {count}',
                                       labelRowsSelect: "lignes"
                                   },
                                   toolbar: {
                                       nRowsSelected: '{0} lignes(s) sélectionnées'
                                   },
                                   header: {
                                       actions: 'Actions'
                                   },
                                   body: {
                                       emptyDataSourceMessage: 'Il n\'y a pas d\'appels traités',
                                       filterRow: {
                                           filterTooltip: 'Filter'
                                       }
                                   }
                               }}
                               components={{
                                   Pagination: props => (
                                       <TablePagination
                                           {...props}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           rowsPerPage={pageSize}
                                           count={totalElements}
                                           page={currentPage}
                                           onChangePage={handlePageChange}
                                           onChangeRowsPerPage={handleRowsPerPageChange}
                                       />
                                   )
                               }}
                /> : <MaterialTable columns={columns}
                                    data={calls}
                                    title='Appels à traiter'
                                    options={{
                                        search: false,
                                        pageSize: pageSize,
                                        sorting: false,
                                        actionsColumnIndex: -1
                                    }}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Créer une course',
                                            onClick: (event, selectedRow) => {
                                                let path = "/courses/creer";
                                                history.push({
                                                    pathname: path,
                                                    state: {
                                                        call: selectedRow
                                                    }
                                                })
                                            }
                                        }
                                    ]}
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} sur {count}',
                                            labelRowsSelect: "lignes"
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} lignes(s) sélectionnées'
                                        },
                                        header: {
                                            actions: 'Actions'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Il n\'y a pas d\'appels à traiter',
                                            filterRow: {
                                                filterTooltip: 'Filter'
                                            }
                                        }
                                    }}
                                    components={{
                                        Pagination: props => (
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[5, 10, 20]}
                                                rowsPerPage={pageSize}
                                                count={totalElements}
                                                page={currentPage}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handleRowsPerPageChange}
                                            />
                                        )
                                    }}
                    /> }
                    </div>
    )
}


