import React, {useEffect} from "react";

// @material-ui/core components
import MaterialTable from 'material-table'
import {TablePagination} from '@material-ui/core';
// @material-ui/icons
// core components


import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {useHistory, useParams} from "react-router-dom";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

export default function ClientRides() {
    const history = useHistory();
    const {id} = useParams()
    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(true)
    const [totalElements, setTotalElements] = React.useState(0)
    const [rides, setRides] = React.useState([])
    const [client, setClient] = React.useState({})

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

    const fetchClient = () => {
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/clients/${id}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                console.log(jsonResult)
                setClient(jsonResult)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const fetchAllRides = () => {
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/rides/clients/${id}?size=${pageSize}&page=${currentPage}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                console.log(jsonResult)
                setTotalElements(jsonResult.totalElements)
                setRides(jsonResult.content)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    const columns = [
        { title: 'Numéro', field: 'rideNumber', searchable: false  },
        { title: 'Adresse de départ', field: 'fromAddress', searchable: false },
        { title: 'Adresse d\'arrivée', field: 'toAddress' , searchable: false },
        { title: 'Départ le', field: 'departingAt', searchable: false  }
    ];

    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value)
    }

    const handlePageChange = (event, newPageNumber) => {
        setCurrentPage(newPageNumber)
        fetchAllRides()
    }

    useEffect(() => {
        fetchAllRides()
    }, [pageSize, currentPage])

    useEffect(() => {
        fetchClient()
    }, [])

    return (
        isLoading ? <div></div> :
        <div>
            <MaterialTable columns={columns}
                           data={rides}
                           title={'Courses ' + client.firstName + ' ' + client.lastName}
                           onRowClick={(event, selectedRow) => {
                               history.push({pathname: `/courses/${selectedRow.id}`})
                               event.stopPropagation()
                           }}
                           options={{
                               search: false,
                               pageSize: pageSize,
                               sorting: false
                           }}
                           localization={{
                               pagination: {
                                   labelDisplayedRows: '{from}-{to} sur {count}',
                                   labelRowsSelect: "lignes"
                               },
                               toolbar: {
                                   nRowsSelected: '{0} lignes(s) sélectionnées'
                               },
                               header: {
                                   actions: 'Actions'
                               },
                               body: {
                                   emptyDataSourceMessage: 'Il n\'y a pas de courses',
                                   filterRow: {
                                       filterTooltip: 'Filter'
                                   }
                               }
                           }}
                           components={{
                               Pagination: props => (
                                   <TablePagination
                                       {...props}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       rowsPerPage={pageSize}
                                       count={totalElements}
                                       page={currentPage}
                                       onChangePage={handlePageChange}
                                       onChangeRowsPerPage={handleRowsPerPageChange}
                                   />
                               )
                           }}
             />
        </div>
    )
}


