import React, {useEffect, useState} from "react";
// react components used to create a google map
import {GoogleMap, Marker, TrafficLayer, withGoogleMap, withScriptjs} from "react-google-maps";

import SearchBox from "react-google-maps/lib/components/places/SearchBox"

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import AddLocation from "@material-ui/icons/AddLocation";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-google-places-autocomplete";

const _ = require("lodash");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const libreIcon = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: '#64be67',
    fillOpacity: 1,
    scale: 0.10, //to reduce the size of icons
    labelOrigin: {
        x: 250,
        y: 250
    }
};

const enPauseIcon = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: '#a1a1a1',
    fillOpacity: 1,
    scale: 0.10, //to reduce the size of icons
    labelOrigin: {
        x: 250,
        y: 250
    }
};

const enCourseIcon = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: '#ff0000',
    fillOpacity: 1,
    scale: 0.10, //to reduce the size of icons
    labelOrigin: {
        x: 250,
        y: 250
    }
};

const enStationIcon = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: '#0000ff',
    fillOpacity: 1,
    scale: 0.10, //to reduce the size of icons
    labelOrigin: {
        x: 250,
        y: 250
    },
};


const MapAll = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            zoom={props.zoom}
            center={props.center}
            defaultOptions={{
                scrollwheel: false,
                streetViewControl: false,
            }}
        >
            <SearchBox
                ref={props.onSearchBoxMounted}
                bounds={props.bounds}
                onPlacesChanged={props.onPlacesChanged}
                controlPosition={2}
            >
                <input
                    type="text"
                    placeholder="Adresse du client"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `540px`,
                        height: `32px`,
                        marginTop: `27px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                    }}
                />
            </SearchBox>
            <TrafficLayer autoUpdate />
            <Marker key="nextMarker" position={props.searchedAddressPosition} />
            {
                props.positions.map(p => (p.driverStatus === 'LIBRE' ? <Marker key={p.id} position={{ lat: p.latitude, lng: p.longitude }} icon={libreIcon} />
                        : (p.driverStatus === 'EN_PAUSE' ? <Marker key={p.id} position={{ lat: p.latitude, lng: p.longitude }} icon={enPauseIcon} />
                        : (p.driverStatus === 'EN_COURSE' ? <Marker key={p.id} position={{ lat: p.latitude, lng: p.longitude }} icon={enCourseIcon} />
                        : <Marker key={p.id} position={{ lat: p.latitude, lng: p.longitude }} icon={enStationIcon} />)))
                )
            }
        </GoogleMap>
    ))
);

const MapGroupement = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            zoom={props.zoom}
            center={props.center}
            defaultOptions={{
                scrollwheel: false,
                streetViewControl: false,
            }}
        >
            <SearchBox
                ref={props.onSearchBoxMounted}
                bounds={props.bounds}
                onPlacesChanged={props.onPlacesChanged}
                controlPosition={2}
            >
                <input
                    type="text"
                    placeholder="Adresse du client"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `540px`,
                        height: `32px`,
                        marginTop: `27px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                    }}
                />
            </SearchBox>
            <TrafficLayer autoUpdate />
            <Marker key="nextMarker" position={props.searchedAddressPosition} />
            {
                props.positions.map(p => (p.driverStatus === 'LIBRE' ?
                    <Marker
                        key={p.id} position={{ lat: p.latitude, lng: p.longitude }} icon={libreIcon} label={{text: p.numberInGroupement.toString(), color: "#ffffff", fontWeight: "bold", fontSize: "20px"}} />
                        : (p.driverStatus === 'EN_PAUSE' ?
                        <Marker
                            key={p.id} position={{ lat: p.latitude, lng: p.longitude }} icon={enPauseIcon} label={{text: p.numberInGroupement.toString(), color: "#ffffff", fontWeight: "bold", fontSize: "20px"}} />
                        : (p.driverStatus === 'EN_COURSE' ?
                            <Marker
                                key={p.id} position={{ lat: p.latitude, lng: p.longitude }} icon={enCourseIcon} label={{text: p.numberInGroupement.toString(), color: "#ffffff", fontWeight: "bold", fontSize: "20px"}} />
                            : <Marker
                                key={p.id} position={{ lat: p.latitude, lng: p.longitude }} icon={enStationIcon} label={{text: p.numberInGroupement.toString(), color: "#ffffff", fontWeight: "bold", fontSize: "20px"}} />)))
                )
            }

        </GoogleMap>
    ))
);


const refsGroupement = {}
const onSearchBoxMountedGroupement = (ref) => {
    refsGroupement.searchBox = ref;
}

const refs = {}
const onSearchBoxMounted = (ref) => {
    refs.searchBox = ref;
}

export default function GoogleMaps() {
  const classes = useStyles();

    const [center, setCenter] = useState({ lat: 48.9833, lng: 1.7167 })
    const [centerGroupement, setCenterGroupement] = useState({ lat: 48.9833, lng: 1.7167 })
    const [zoom, setZoom] = useState(11)
    const [zoomGroupement, setZoomGroupement] = useState(11)
    const [positions, setPositions] = useState([])
    const [positionsGroupement, setPositionsGroupement] = useState([])
    const [searchedAddressPosition, setSearchedAddressPosition] = useState({})
    const [searchedAddressPositionGroupement, setSearchedAddressPositionGroupement] = useState({})

    useEffect(() => {
        const intervalId = setInterval(() => {
            retrieveDriversLastPositions()
        }, 1000)
        return () => {
            clearInterval(intervalId)
        }
    })

    const retrieveDriversLastPositions = () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

        fetch('https://api.taximoove.fr/drivers/position/last', {
            credentials: 'include',
            method: 'GET',
            mode: 'cors',
            headers: headers
        })
            .then(result => result.json())
            .then(jsonResult => {
                const groupementPositions = []
                const allDriversPositions = jsonResult

                const groupementId = localStorage.getItem("groupementId");
                jsonResult.forEach(json => {
                    if(json.groupementId) {
                        if(parseInt(json.groupementId) === parseInt(groupementId)) {
                            groupementPositions.push(json)
                        }
                    }
                })

                setPositions(allDriversPositions)
                setPositionsGroupement(groupementPositions)
            })
            .catch(error => console.log("Error : " + error))
    }

    const onPlacesChangedGroupement = () => {
        const places = refsGroupement.searchBox.getPlaces();
        const nextMarkers = places.map(place => ({
            position: place.geometry.location,
        }));
        const nextCenter = _.get(nextMarkers, '0.position', center);
        console.log(nextCenter)

        setSearchedAddressPositionGroupement(nextCenter)
        setCenterGroupement(nextCenter)
        setZoomGroupement(14)
    }

    const onPlacesChanged = () => {
        const places = refs.searchBox.getPlaces();
        const nextMarkers = places.map(place => ({
            position: place.geometry.location,
        }));
        const nextCenter = _.get(nextMarkers, '0.position', center);
        console.log(nextCenter)

        setSearchedAddressPosition(nextCenter)
        setCenter(nextCenter)
        setZoom(14)
    }

    const userRole = localStorage.getItem("role")

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
          {
              !userRole.includes('HOSPITAL') ?
                  <div>
                      <Card>
                          <CardHeader color="rose" icon>
                              <CardIcon color="rose">
                                  <AddLocation />
                              </CardIcon>
                              <h4 className={classes.cardIconTitle}>Mon groupement</h4>
                          </CardHeader>
                          <CardBody>
                              <MapGroupement
                                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAUN00g3uUI5EnpfHaSheg_bHy6jWA5vTo&libraries=geometry,drawing,places&callback=initMap"
                                  loadingElement={<div style={{ height: `100%` }} />}
                                  containerElement={
                                      <div
                                          style={{
                                              height: `800px`,
                                              borderRadius: "6px",
                                              overflow: "hidden"
                                          }}
                                      />
                                  }
                                  mapElement={<div style={{ height: `100%` }} />}
                                  positions={positionsGroupement}
                                  center={centerGroupement}
                                  zoom={zoomGroupement}
                                  onSearchBoxMounted={onSearchBoxMountedGroupement}
                                  onPlacesChanged={onPlacesChangedGroupement}
                                  searchedAddressPosition={searchedAddressPositionGroupement}
                              />
                          </CardBody>
                      </Card>
                      <Card>
                          <CardHeader color="rose" icon>
                              <CardIcon color="rose">
                                  <AddLocation />
                              </CardIcon>
                              <h4 className={classes.cardIconTitle}>Toute la carte</h4>
                          </CardHeader>
                          <CardBody>
                              <MapAll
                                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAUN00g3uUI5EnpfHaSheg_bHy6jWA5vTo&libraries=geometry,drawing,places&callback=initMap"
                                  loadingElement={<div style={{ height: `100%` }} />}
                                  containerElement={
                                      <div
                                          style={{
                                              height: `800px`,
                                              borderRadius: "6px",
                                              overflow: "hidden"
                                          }}
                                      />
                                  }
                                  mapElement={<div style={{ height: `100%` }} />}
                                  positions={positions}
                                  center={center}
                                  zoom={zoom}
                                  onSearchBoxMounted={onSearchBoxMounted}
                                  onPlacesChanged={onPlacesChanged}
                                  searchedAddressPosition={searchedAddressPosition}
                              />
                          </CardBody>
                      </Card>
                  </div> :
                  <Card>
                      <CardHeader color="rose" icon>
                          <CardIcon color="rose">
                              <AddLocation />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Toute la carte</h4>
                      </CardHeader>
                      <CardBody>
                          <MapAll
                              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAUN00g3uUI5EnpfHaSheg_bHy6jWA5vTo&libraries=geometry,drawing,places&callback=initMap"
                              loadingElement={<div style={{ height: `100%` }} />}
                              containerElement={
                                  <div
                                      style={{
                                          height: `800px`,
                                          borderRadius: "6px",
                                          overflow: "hidden"
                                      }}
                                  />
                              }
                              mapElement={<div style={{ height: `100%` }} />}
                              positions={positions}
                              center={center}
                              zoom={zoom}
                              onSearchBoxMounted={onSearchBoxMounted}
                              onPlacesChanged={onPlacesChanged}
                              searchedAddressPosition={searchedAddressPosition}
                          />
                      </CardBody>
                  </Card>
          }

      </GridItem>
    </GridContainer>
  );
}
