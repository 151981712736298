import React from "react";
import cx from "classnames";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import ClientDetails from "../views/Clients/ClientDetails";
import ClientRides from "../views/Clients/ClientRides";
import RideDetails from "../views/Rides/RideDetails";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import RideDispatchInfo from "../views/Rides/RideDispatchInfo";
import CreateOrUpdateRide from "../views/Rides/CreateOrUpdateRide";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo-white.svg"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });


  // functions for changeing the states from components
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleBgColorClick = bgColor => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

     if (prop.layout === "" && prop.path !== "/connexion") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            userRole={prop.userRole}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const logOperatorInOrOut = (loggingIn) => {
    console.log(loggingIn ? "Logging operator in" : "Logging operator out")
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

    fetch(`https://api.taximoove.fr/users?loggingIn=${loggingIn}`, {
      mode: 'cors',
      credentials: 'include',
      method: 'PUT',
      headers: headers
    })
        .then(result => localStorage.setItem("operatorLoggedIn", loggingIn))
        .catch(error => console.log("Error : " + error))
  }

  const history = useHistory();

  if (localStorage.getItem("groupementType").toString() === "GROUPEMENT_WITH_OPERATOR") {
    console.log("importing script groupement type " + localStorage.getItem("groupementType").toString())
    const script = document.createElement('script');
    script.src = "https://taximoove-bucket.s3.fr-par.scw.cloud/scripts/ziwo.js";
    script.async = true;
    document.body.appendChild(script);
  }

  if(localStorage.getItem("firstLogin") === "yes") {
    localStorage.setItem("firstLogin", "no")
    console.log("FIRST LOGIN")
    window.location.reload()
    return
  }
  console.log("USER ROLE ADMIN PAGE : " + localStorage.getItem("role"))

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={localStorage.getItem("groupementName")}
        logo={localStorage.getItem("groupementLogo")}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {(
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Route path="/courses/:id/distribution" component={RideDispatchInfo} name="Informations de distribution" />
                <Route path="/courses/:id/modifier" component={CreateOrUpdateRide} name="Modifier Course" />
                <Route path="/courses/:id" component={RideDetails} name="Course" />
                <Route path="/clients/:id/courses" component={ClientRides} name="Courses du client" />
                <Route path="/clients/:id" component={ClientDetails} name="Client" />
                <Redirect from="/" to="/carte" />
              </Switch>
            </div>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
        {
          /*

          <FixedPlugin
              handleImageClick={handleImageClick}
              handleColorClick={handleColorClick}
              handleBgColorClick={handleBgColorClick}
              color={color}
              bgColor={bgColor}
              bgImage={image}
              handleFixedClick={handleFixedClick}
              fixedClasses={fixedClasses}
              sidebarMinimize={sidebarMinimize.bind(this)}
              miniActive={miniActive}
          />*/
        }
      </div>

      {
        /** Login event listener **/
        window.addEventListener('_jorel-login', () => {
          let loggedIn = localStorage.getItem("operatorLoggedIn")
          console.log("Jorel login " + loggedIn)
          if(loggedIn == undefined || loggedIn == null || loggedIn === "false") {
            logOperatorInOrOut(true)
          }
        })
      }

      {
        /** Logout event listener **/
        window.addEventListener('_jorel-logout', () => {
          console.log("Jorel logout")
          let loggedIn = localStorage.getItem("operatorLoggedIn")
          if(loggedIn === "true") {
            logOperatorInOrOut(false)
          }
        })
      }

      {
        window.addEventListener('_jorel-dialog-state-ringing', (event) => {
          console.log("@LL -- ringing --")
          const customerPhoneNumber = event.detail.customerNumber
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

          fetch(`https://api.taximoove.fr/clients?phoneNumber=${customerPhoneNumber}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
          })
              .then(result => {
                console.log(result)
                return result.json()
              })
              .then(clientJson => {
                let path = `/courses/creer?clientId=${clientJson.id}`;
                history.push(path);
              })
              .catch(error => {
                let path = `/courses/creer`;
                history.push(path);
              })
        })
      }

      {
        window.addEventListener('_jorel-dialog-state-answering', (event) => {
          console.log("@LL -- answering --")
          let alreadyCalledApiForThisCall = localStorage.getItem("alreadyCalledApiForThisCall")
          if(alreadyCalledApiForThisCall == null || alreadyCalledApiForThisCall === "false") {
            localStorage.setItem("alreadyCalledApiForThisCall", true)
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

            let call = {
              ziwoCallId: event.detail.primaryCallID,
              calledNumber: localStorage.getItem("groupementPhoneNumber"),
              destinationNumber: localStorage.getItem("groupementPhoneNumber"),
              callerNumber: event.detail.customerNumber
            }

            fetch(`https://api.taximoove.fr/calls`, {
              mode: 'cors',
              credentials: 'include',
              method: 'POST',
              body: JSON.stringify(call),
              headers: headers
            })
                .then(response => {
                  console.log(response)
                  if (response === 200) {
                    // TODO : OK
                  } else {
                    // TODO : KO
                  }
                })
                .catch(error => console.log("Error : " + error))
          }
        })
      }

      {
        window.addEventListener('_jorel-dialog-state-hangup', (event) => {
          console.log("@LL -- hangup --")
          console.log(event.detail)

          let alreadyCalledApiForThisCall = localStorage.getItem("alreadyCalledApiForThisCall")
          if(alreadyCalledApiForThisCall != null && alreadyCalledApiForThisCall === "true") {
            localStorage.setItem("alreadyCalledApiForThisCall", false)
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

            let call = {
              ziwoCallId: event.detail.primaryCallID,
              fileUrl: "https://aom-api.aswat.co/surveillance/recordings/" + event.detail.primaryCallID + ".mp3"
            }

            fetch(`https://api.taximoove.fr/calls`, {
              mode: 'cors',
              credentials: 'include',
              method: 'PUT',
              body: JSON.stringify(call),
              headers: headers
            })
                .then(response => {
                  console.log(response)
                  if (response === 200) {
                    // TODO : OK
                  } else {
                    // TODO : KO
                  }
                })
                .catch(error => console.log("Error : " + error))
          }
        })
      }
    </div>
  );
}
