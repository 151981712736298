/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "./views/Pages/LoginPage";
import AdminLayout from "./layouts/Admin";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
      <Switch>
          <Route path="/connexion" component={LoginPage} />
          <PrivateRoute />
      </Switch>
  </Router>,
  document.getElementById("root")
);
