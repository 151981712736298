import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText'
import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import 'moment/locale/fr';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import SweetAlert from "react-bootstrap-sweetalert";
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng
} from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "../../components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import '../../assets/css/autosuggest.css'
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import parse from "date-fns/parse";
import frLocale from "date-fns/locale/fr";
import {useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Typography from "@material-ui/core/Typography";
import parseAutoSuggest from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const useStyles = makeStyles(styles);
const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const useStylesCustomSelect = makeStyles(customSelectStyle)
const classNames = require('classnames');

class FrLocalizedUtils extends DateFnsUtils {
    getDateTimePickerHeaderText(date) {
        return format(date, "dd/MM", { locale: frLocale });
    }
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const useStylesAutocomplete = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function CreateOrUpdateRide() {
    let query = useQuery();

    const classes = useStyles();
    const classesCustomSelect = useStylesCustomSelect()
    const classesSweetAlert = useStylesSweetAlert()
    const selectClasses = classNames(classesCustomSelect.autocompleteTextField)

    const location = useLocation()
    const history = useHistory();

    const [alert, setAlert] = useState(null);
    const [departureDate, setDepartureDate] = useState(new Date())
    const [rideBackDepartureDate, setRideBackDepartureDate] = useState(new Date())
    const [rideBackDepartureDateStr, setRideBackDepartureDateStr] = useState("")
    const [filteredClients, setFilteredClients] = useState([])
    const [clientsLoading, setClientsLoading] = useState(false)
    const [hasRideBack, setHasRideBack] = useState(false)
    const [lastNameInputValue, setLastNameInputValue] = useState("")

    const classesAutocomplete = useStylesAutocomplete();
    const [fromAddress, setFromAddress] = useState('');
    const [fromAddressInputValue, setFromAddressInputValue] = useState("")
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    const [isUpdate, setIsUpdate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [ride, setRide] = useState({
        departingAt: convertDateTimeToString(departureDate),
        vehicleType: "ANY",
        handicapedClient: false,
        pets: false,
        nbAdults: 1,
        nbLugages: 0,
        nbChildren: 0,
        paymentType: "ANY",
        type: "COMPTEUR",
        client: {
            id: 0,
            gender: "",
            firstName: "",
            lastName: "",
            company: "",
            mobilePhoneNumber: "",
            homePhoneNumber: ""
        }
    })

    const {id} = useParams()
    useEffect(() => {
        if(window.location.href.includes("modifier")) {
            setIsUpdate(true)
            fetchRide(id)
        }

        if(location.state !== undefined && location.state !== null && location.state.call !== undefined && location.state.call !== null) {
            const call = location.state.call
            const client = location.state.call.client

            if(client !== undefined && client !== null) {
                setRide(prevState => ({
                    ...prevState,
                    call: call,
                    client: client
                }))

                const lastName = client.lastName
                if (lastName !== undefined && lastName !== null) {
                    setLastNameInputValue(lastName)
                }

                const addresses = client.addresses
                if (addresses !== undefined && addresses.length > 0) {
                    addresses.forEach(address => {
                        if (address.type.code === 'HOME') {
                            setFromAddress(address.address)
                            setRide(prevState => ({
                                    ...prevState,
                                    fromAddress: address.address,
                                    fromPostalCode: address.city.postalCode,
                                    fromCityName: address.city.name,
                                    fromDepartmentName: address.city.departmentName,
                                    fromLatitude: address.latitude,
                                    fromLongitude: address.longitude
                                })
                            )
                        }
                    })
                }
            } else {
                setRide(prevState => ({
                    ...prevState,
                    call: call
                }))
            }
        }
    }, [])

    useEffect(() => {
        let clientId = query.get("clientId")
        if(clientId !== undefined && clientId !== null) {
            setIsLoading(true)
            fetch(`https://api.taximoove.fr/clients/${clientId}`, {
                mode: 'cors',
                credentials: 'include',
                method: 'GET',
                headers: headers
            })
                .then(result => result.json())
                .then(clientJson => {
                    console.log(clientJson)
                    setLastNameInputValue(clientJson.lastName)
                    if(clientJson.addresses !== undefined && clientJson.addresses.length > 0) {
                        clientJson.addresses.forEach(address => {
                            if(address.type.code === 'HOME') {
                                console.log(address)
                                console.log(ride)
                                setFromAddress(address.address)
                                setRide(prevState => ({
                                        ...prevState,
                                        client: clientJson,
                                        fromAddress: address.address,
                                        fromPostalCode : address.city.postalCode,
                                        fromCityName : address.city.name,
                                        fromDepartmentName : address.city.departmentName,
                                        fromLatitude: address.latitude,
                                        fromLongitude: address.longitude
                                    })
                                )
                            }
                        })
                    } else {
                        setRide({
                            ...ride,
                            client: clientJson
                        })
                    }
                    setIsLoading(false)
                })
                .catch(error => {
                    console.log("Error : " + error)
                    setIsLoading(false)
                })
        }
    }, [])

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

    const fetchRide = (rideId) => {
        setIsLoading(true)
        fetch(`https://api.taximoove.fr/rides/${rideId}`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        })
            .then(result => result.json())
            .then(rideJson => {
                console.log(rideJson.departingAt)
                setFromAddress(rideJson.fromAddress)
                setLastNameInputValue(rideJson.client.lastName)
                setDepartureDate(parse(rideJson.departingAt, 'dd/MM/yyyy HH:mm', new Date()))
                setRide(rideJson)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("Error : " + error)
                setIsLoading(false)
            })
    }

    function convertDateTimeToString(date) {
        let dateStr = ""
        if(date.getDate() > 9) {
            dateStr = date.getDate()
        } else {
            dateStr = "0" + date.getDate()
        }

        if((date.getMonth()+1) > 9) {
            dateStr = dateStr + "/" + (date.getMonth() + 1)
        } else {
            dateStr = dateStr + "/0" + (date.getMonth() + 1)
        }


        dateStr = dateStr + "/" + date.getFullYear()

        if(date.getHours() > 9) {
            dateStr = dateStr + " " + date.getHours()
        } else {
            dateStr = dateStr + " 0" + date.getHours()
        }

        if(date.getMinutes() > 9) {
            dateStr = dateStr + ":" + date.getMinutes()
        } else {
            dateStr = dateStr + ":0" + date.getMinutes()
        }

        return dateStr
    }

    const handleChangeInClient = (event) => {
        const client = {
            ...ride.client,
            [event.target.name]: event.target.value
        }

        setRide(prevState => ({
            ...prevState,
            client: client
        }))
    }

    const handleChangeInRide = (event) => {
        setRide({
            ...ride,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if(isUpdate) {
            console.log(ride)
            fetch(`https://api.taximoove.fr/rides`, {
                mode: 'cors',
                credentials: 'include',
                method: 'PUT',
                body: JSON.stringify(ride),
                headers: headers
            })
                .then(response => {
                    if(response.status === 200) {
                        return response.json()
                    } else {
                        console.log(response)
                        createOrUpdateRideError(response.statusText)
                    }
                })
                .then(jsonResult => {
                    createOrUpdateRideSuccess(jsonResult.id)
                })
                .catch(error => {
                    createOrUpdateRideError(error.value)
                    console.log("Error : " + error)
                })
        } else {
            let rideToBeSent = ride
            if(hasRideBack) {
                rideToBeSent = {
                    ...rideToBeSent,
                    rideBack: {
                        ...ride,
                        departingAt: rideBackDepartureDateStr,
                        fromAddress: ride.toAddress,
                        fromPostalCode : ride.toPostalCode,
                        fromCityName : ride.toCityName,
                        fromDepartmentName : ride.toDepartmentName,
                        fromLatitude: ride.toLatitude,
                        fromLongitude: ride.toLongitude,
                        toAddress: ride.fromAddress,
                        toPostalCode : ride.fromPostalCode,
                        toCityName : ride.fromCityName,
                        toDepartmentName : ride.fromDepartmentName,
                        toLatitude: ride.fromLatitude,
                        toLongitude: ride.fromLongitude
                    }
                }
            }

            console.log(rideToBeSent)
            fetch(`https://api.taximoove.fr/rides`, {
                mode: 'cors',
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify(rideToBeSent),
                headers: headers
            })
                .then(response => {
                    if(response.status === 200) {
                        return response.json()
                    } else {
                        console.log(response)
                        createOrUpdateRideError(response.statusText)
                    }
                })
                .then(jsonResult => {
                    createOrUpdateRideSuccess(jsonResult.id)
                })
                .catch(error => {
                    createOrUpdateRideError(error.value)
                    console.log("Error : " + error)
                })
        }
    }

    const hideAlert = () => {
        setAlert(null)
    }

    const createOrUpdateRideSuccess = (rideId) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Félicitations !"
                onConfirm={() => {
                    hideAlert()
                    history.push({pathname: `/courses/${rideId}`})
                }}
                onCancel={() => {
                    hideAlert()
                    history.push({pathname: `/courses/${rideId}`})
                }}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
            >
                La course a correctement été {isUpdate ? "modifiée" : "crée" }
            </SweetAlert>
        );
    };

    const createOrUpdateRideError = (errorMessage) => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Erreur !"
                onConfirm={() => {
                    hideAlert()
                }}
                onCancel={() => {
                    hideAlert()
                }}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
            >
                {errorMessage}
            </SweetAlert>
        )
    }

    const handleAddressChange = (newAddress, addressType) => {
        console.log(newAddress)
        const {place_id, description} = newAddress

        geocodeByPlaceId(place_id)
            .then(results => {
                let address = ""
                let postalCode = ""
                let cityName = ""
                let departmentName = ""
                let latitude = 0
                let longitude = 0

                results[0].address_components.forEach(addressComponent => {
                    if(addressComponent.types.includes("postal_code")) {
                        postalCode = addressComponent.long_name
                    } else if(addressComponent.types.includes("locality")) {
                        cityName = addressComponent.long_name
                    } else if(addressComponent.types.includes("administrative_area_level_2")) {
                        departmentName = addressComponent.long_name
                    }
                })

                address = results[0].formatted_address

                console.log("Address : " + address)

                geocodeByAddress(description)
                    .then(results => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                            latitude = lat
                            longitude = lng

                            if(addressType === "FROM") {
                                setRide({
                                    ...ride,
                                    fromAddress: address,
                                    fromPostalCode : postalCode,
                                    fromCityName : cityName,
                                    fromDepartmentName : departmentName,
                                    fromLatitude: latitude,
                                    fromLongitude: longitude
                                })
                            } else {
                                setRide({
                                    ...ride,
                                    toAddress: address,
                                    toPostalCode : postalCode,
                                    toCityName : cityName,
                                    toDepartmentName : departmentName,
                                    toLatitude: latitude,
                                    toLongitude: longitude
                                })
                            }
                        }
                    );
            })
            .catch(error => console.error(error));
    }

    const handleDepartureDateChange = (date, value) => {
        console.log(date + ' ' + value)
        setDepartureDate(date)
        setRide({
            ...ride,
            departingAt: value
        })
    }

    const handleRideBackDepartureDateChange = (date, value) => {
        setRideBackDepartureDate(date)
        setRideBackDepartureDateStr(value)
    }

    // Filter logic
    const fetchClients = async (value) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

        const inputValue = value.trim().toLowerCase();
        let response = await fetch("https://api.taximoove.fr/clients/groupement/filter/" + inputValue, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: headers
        });

        if(response.status !== 200) {
            return []
        }
        let data = await response.json()

        return data;
    };

    // OnChange event handler
    const onChangeClientLastName = (filter) => {
        setLastNameInputValue(filter)
        if(filter.length < 3) {
            setFilteredClients([])
        } else {
            setClientsLoading(true)
            fetchClients(filter)
                .then(data => {
                    setFilteredClients(data)
                    setClientsLoading(false)
                })
        }
    }
    //////////// AUTOCOMPLETE

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyAUN00g3uUI5EnpfHaSheg_bHy6jWA5vTo&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetchAutocomplete = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (fromAddressInputValue === '') {
            setOptions(fromAddress ? [fromAddress] : []);
            return undefined;
        }

        fetchAutocomplete({ input: fromAddressInputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (fromAddress) {
                    newOptions = [fromAddress];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [fromAddress, fromAddressInputValue, fetch]);


    //////////// END AUTOCOMPLETE

    return (
        <div>
            {alert}
            {
                isLoading ? "" :
                <form onSubmit={handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <PermIdentity/>
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Informations sur le client
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={2}>
                                            <FormControl fullWidth
                                                         className={selectClasses}>
                                                <Select
                                                    labelId="gender"
                                                    id="gender"
                                                    name="gender"
                                                    onChange={handleChangeInClient}
                                                    value={ride.client.gender}
                                                >
                                                    <MenuItem value="" disabled></MenuItem>
                                                    <MenuItem value="MADAM">Mme</MenuItem>
                                                    <MenuItem value="MISTER">M.</MenuItem>
                                                </Select>
                                                <FormHelperText>Genre</FormHelperText>
                                            </FormControl>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={5}>
                                            <Autocomplete
                                                freeSolo
                                                id="lastName"
                                                options={filteredClients}
                                                loading={clientsLoading}
                                                loadingText={"Chargement des clients en cours..."}
                                                noOptionsText={"Il n'y a pas de client avec ce nom"}
                                                onInputChange={(event, newValue) => onChangeClientLastName(newValue)}
                                                onClose={(event, reason) => {
                                                    if (reason !== 'select-option') {
                                                        let lastName = event.target.value
                                                        setRide(prevState => ({
                                                                ...prevState,
                                                                client: {
                                                                    ...ride.client,
                                                                    id: null,
                                                                    lastName: lastName
                                                                }
                                                            })
                                                        )
                                                    }
                                                }}
                                                onChange={(event, value, reason) => {
                                                    if (reason === 'select-option') {
                                                        const filteredClient = {
                                                            id: value.id,
                                                            gender: value.gender,
                                                            lastName: value.lastName,
                                                            firstName: value.firstName === undefined ? "" : value.firstName,
                                                            company: value.company === undefined ? "" : value.company,
                                                            mobilePhoneNumber: value.mobilePhoneNumber === undefined ? "" : value.mobilePhoneNumber,
                                                            homePhoneNumber: value.homePhoneNumber === undefined ? "" : value.homePhoneNumber
                                                        }

                                                        if(value.addresses !== undefined && value.addresses.length > 0) {
                                                              value.addresses.forEach(address => {
                                                                  if(address.type.code === 'HOME') {
                                                                      console.log(address)
                                                                      console.log(ride)
                                                                      setFromAddress(address.address)
                                                                      setRide(prevState => ({
                                                                              ...prevState,
                                                                              client: filteredClient,
                                                                              fromAddress: address.address,
                                                                              fromPostalCode : address.city.postalCode,
                                                                              fromCityName : address.city.name,
                                                                              fromDepartmentName : address.city.departmentName,
                                                                              fromLatitude: address.latitude,
                                                                              fromLongitude: address.longitude
                                                                          })
                                                                      )
                                                                  }
                                                              })
                                                        } else {
                                                            setRide(prevState => ({
                                                                    ...prevState,
                                                                    client: filteredClient
                                                                })
                                                            )
                                                        }
                                                    }
                                                }}
                                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.lastName)}
                                                value={lastNameInputValue}
                                                renderInput={(params) => <TextField {...params}
                                                                                    className={selectClasses}
                                                                                    helperText="Nom" fullWidth/>}
                                                renderOption={(option) => {
                                                    return (<span>{option.firstName + " " + option.lastName}</span>)
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={5}>
                                            <CustomInput
                                                id="firstName"
                                                helperText="Prénom"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => {
                                                        handleChangeInClient(event)
                                                    },
                                                    name: "firstName",
                                                    value: ride.client.firstName
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                id="company"
                                                helperText="Société"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => {
                                                        handleChangeInClient(event)
                                                    },
                                                    name: "company",
                                                    value: ride.client.company
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                id="mobilePhoneNumber"
                                                helperText="Téléphone portable"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => {
                                                        handleChangeInClient(event)
                                                    },
                                                    name: "mobilePhoneNumber",
                                                    value: ride.client.mobilePhoneNumber
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                id="homePhoneNumber"
                                                helperText="Téléphone Fixe"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => {
                                                        handleChangeInClient(event)
                                                    },
                                                    name: "homePhoneNumber",
                                                    value: ride.client.homePhoneNumber
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className={classes.cardIconTitle}>
                                        Informations sur la course
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <FormControl fullWidth
                                                         className={selectClasses}>
                                                <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
                                                    <KeyboardDateTimePicker
                                                        variant="inline"
                                                        ampm={false}
                                                        value={departureDate}
                                                        onChange={handleDepartureDateChange}
                                                        onError={console.log}
                                                        format="dd/MM/yyyy HH:mm"
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <FormHelperText>Date de départ</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <Autocomplete
                                                freeSolo
                                                id="google-map-demo"
                                                style={{ width: "100%" }}
                                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                                filterOptions={(x) => x}
                                                options={options}
                                                autoComplete
                                                includeInputInList
                                                filterSelectedOptions
                                                value={fromAddress}
                                                onChange={(event, newValue, reason) => {
                                                    setOptions(newValue ? [newValue, ...options] : options);
                                                    setFromAddress(newValue);

                                                    if (reason === 'select-option') {
                                                        console.log(newValue)
                                                        handleAddressChange(newValue, "FROM")
                                                    }
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                    setFromAddressInputValue(newInputValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} className={selectClasses}  helperText={"Adresse de départ"} />
                                                )}
                                                renderOption={(option) => {
                                                    if (option.structured_formatting !== undefined) {
                                                        const matches = option.structured_formatting.main_text_matched_substrings;
                                                        const parts = parseAutoSuggest(
                                                            option.structured_formatting.main_text,
                                                            matches.map((match) => [match.offset, match.offset + match.length]),
                                                        );

                                                        return (
                                                            <Grid container alignItems="center">
                                                                <Grid item>
                                                                    <LocationOnIcon className={classesAutocomplete.icon} />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    {parts.map((part, index) => (
                                                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                                          {part.text}
                                                                        </span>
                                                                    ))}

                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {option.structured_formatting.secondary_text}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <GooglePlacesAutocomplete
                                                helperText={"Adresse d'arrivée"}
                                                address={ride.toAddress}
                                                name={"toAddress"}
                                                onChange={(newAddress) => handleAddressChange(newAddress, "TO")} />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                id="nbAdults"
                                                helperText="Nombre d'adultes"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => {
                                                        handleChangeInRide(event)
                                                    },
                                                    name: "nbAdults",
                                                    type: "number",
                                                    value: ride.nbAdults
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                id="nbChildren"
                                                helperText="Nombre d'enfants"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => {
                                                        handleChangeInRide(event)
                                                    },
                                                    name: "nbChildren",
                                                    type: "number",
                                                    value: ride.nbChildren
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                id="nbLugages"
                                                helperText="Nombre de bagages"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => {
                                                        handleChangeInRide(event)
                                                    },
                                                    name: "nbLugages",
                                                    type: "number",
                                                    value: ride.nbLugages
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormControl
                                                fullWidth
                                                className={classesCustomSelect.selectFormControl}>
                                                <Select
                                                    labelId="paymentType"
                                                    id="paymentType"
                                                    name="paymentType"
                                                    onChange={handleChangeInRide}
                                                    value={ride.paymentType}
                                                >
                                                    <MenuItem value="ANY">Indifférent</MenuItem>
                                                    <MenuItem value="CASH">Espèces</MenuItem>
                                                    <MenuItem value="BANK_CARD">Carte Bancaire</MenuItem>
                                                    <MenuItem value="AMEX">American Express</MenuItem>
                                                    <MenuItem value="CHECK">Chèque</MenuItem>
                                                    <MenuItem value="WIRE_TRANSFER">Virement</MenuItem>
                                                    <MenuItem value="COURSE_EN_COMPTE">Course en compte</MenuItem>
                                                    <MenuItem value="FORFAIT_COURSE_PLAFONNEE">Forfait course
                                                        plafonnée</MenuItem>
                                                    <MenuItem value="CPAM_FULL">CPAM 100%</MenuItem>
                                                    <MenuItem value="CPAM_65_35">CPAM 65/35</MenuItem>
                                                </Select>
                                                <FormHelperText>Type de paiement</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormControl
                                                fullWidth
                                                className={classesCustomSelect.selectFormControl}>
                                                <Select
                                                    labelId="vehicleType"
                                                    id="vehicleType"
                                                    name="vehicleType"
                                                    onChange={handleChangeInRide}
                                                    value={ride.vehicleType}
                                                >
                                                    <MenuItem value="ANY">Indifférent</MenuItem>
                                                    <MenuItem value="SEDAN">Berline</MenuItem>
                                                    <MenuItem value="BREAK">Break</MenuItem>
                                                    <MenuItem value="MONOSPACE">Monospace</MenuItem>
                                                    <MenuItem value="VAN">VAN</MenuItem>
                                                </Select>
                                                <FormHelperText>Type de véhicule</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormControl
                                                fullWidth
                                                className={classesCustomSelect.selectFormControl}>
                                                <Select
                                                    labelId="handicapedClient"
                                                    id="handicapedClient"
                                                    name="handicapedClient"
                                                    onChange={handleChangeInRide}
                                                    value={ride.handicapedClient}
                                                >
                                                    <MenuItem value={true}>Oui</MenuItem>
                                                    <MenuItem value={false}>Non</MenuItem>
                                                </Select>
                                                <FormHelperText>PMR</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormControl
                                                fullWidth
                                                className={classesCustomSelect.selectFormControl}>
                                                <Select
                                                    labelId="pets"
                                                    id="pets"
                                                    name="pets"
                                                    onChange={handleChangeInRide}
                                                    value={ride.pets}
                                                >
                                                    <MenuItem value={true}>Oui</MenuItem>
                                                    <MenuItem value={false}>Non</MenuItem>
                                                </Select>
                                                <FormHelperText>Présence d'animaux</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                id="remarks"
                                                helperText="Commentaires"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    onChange: event => {
                                                        handleChangeInRide(event)
                                                    },
                                                    name: "remarks",
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    {isUpdate ? "" :
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={2}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={hasRideBack}
                                                            onChange={(event) => {
                                                                setHasRideBack(event.target.checked)
                                                            }}
                                                            name="hasRideBack"
                                                        />
                                                    }
                                                    label="Retour"
                                                />
                                            </GridItem>

                                            {hasRideBack ? <GridItem xs={12} sm={12} md={4}>
                                                <FormControl fullWidth>
                                                    <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
                                                        <KeyboardDateTimePicker
                                                            variant="inline"
                                                            ampm={false}
                                                            label="Date de départ du retour"
                                                            value={rideBackDepartureDate}
                                                            onChange={handleRideBackDepartureDateChange}
                                                            onError={console.log}
                                                            disablePast
                                                            format="dd/MM/yyyy HH:mm"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </FormControl>
                                            </GridItem> : ""}
                                        </GridContainer>
                                    }

                                    <Button color="rose" className={classes.updateProfileButton} type="submit">
                                        Valider
                                    </Button>
                                    <Clearfix/>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </form>
            }
        </div>
    )
}