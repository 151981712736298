import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SweetAlert from "react-bootstrap-sweetalert";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import GooglePlacesAutocomplete from "../../components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-google-places-autocomplete";

const useStyles = makeStyles(styles);
const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const useStylesCustomSelect = makeStyles(customSelectStyle)
const classNames = require('classnames');

export default function CreateClient() {
    const history = useHistory();

    const classes = useStyles();
    const classesSweetAlert = useStylesSweetAlert();
    const classesCustomSelect = useStylesCustomSelect()
    const selectClasses = classNames(classesCustomSelect.autocompleteTextField)

    const [client, setClient] = useState({})
    const [alert, setAlert] = React.useState(null);
    const {id} = useParams()

    const handleChange = (event) => {
        setClient({
            ...client,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log(client)

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))
        fetch(`https://api.taximoove.fr/clients`, {
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(client),
            headers: headers
        })
            .then(response => response.json())
            .then(jsonResult => {
                createClientSuccess(jsonResult.id)
            })
            .catch(error => {
                // TODO : Set error alert
                console.log("Error : " + error)
            })
    }

    const hideAlert = () => {
        setAlert(null)
    }

    const createClientSuccess = (clientId) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Félicitations !"
                onConfirm={() => {
                    history.push({pathname: `/clients/${clientId}`})
                    hideAlert()
                }}
                onCancel={() => {
                    hideAlert()
                }}
                confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
            >
                Le client a correctement été crée
            </SweetAlert>
        );
    };

    const handleAddressChange = (newAddress, addressType) => {
        console.log(newAddress)
        const {place_id, description} = newAddress

        geocodeByPlaceId(place_id)
            .then(results => {
                let address = ""
                let postalCode = ""
                let cityName = ""
                let departmentName = ""
                let latitude = 0
                let longitude = 0

                results[0].address_components.forEach(addressComponent => {
                    if(addressComponent.types.includes("postal_code")) {
                        postalCode = addressComponent.long_name
                    } else if(addressComponent.types.includes("locality")) {
                        cityName = addressComponent.long_name
                    } else if(addressComponent.types.includes("administrative_area_level_2")) {
                        departmentName = addressComponent.long_name
                    }
                })

                address = results[0].formatted_address

                console.log("Address : " + address)

                geocodeByAddress(description)
                    .then(results => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                            latitude = lat
                            longitude = lng

                            let addresses = client.addresses
                            let hasHomeAddress = false

                            const homeAddress = {
                                address: address,
                                city: {
                                    name: cityName,
                                    postalCode: postalCode,
                                    departmentName: departmentName
                                },
                                type: {
                                    code: addressType
                                },
                                latitude: latitude,
                                longitude: longitude
                            }

                            if(addresses !== undefined && addresses.length > 0) {
                                addresses.map(ad => {
                                    if(ad.type.code === addressType) {
                                        ad.address = address
                                        ad.city.name = cityName
                                        ad.city.postalCode = postalCode
                                        ad.city.departmentName = departmentName
                                        ad.latitude = latitude
                                        ad.longitude = longitude

                                        hasHomeAddress = true
                                    }
                                })
                            } else {
                                addresses = []
                            }

                            if(!hasHomeAddress) {
                                addresses.push(homeAddress)
                            }
                            setClient({
                                ...client,
                                addresses: addresses
                            })
                        }
                    );
            })
            .catch(error => console.error(error));
    }

    return (
        <div>
            {alert}
            <form onSubmit={handleSubmit}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <PermIdentity/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Créer client
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={1}>
                                        <FormControl
                                            fullWidth
                                            className={selectClasses}>
                                            <Select
                                                labelId="gender"
                                                id="gender"
                                                name="gender"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="MADAM">Mme</MenuItem>
                                                <MenuItem value="MISTER">M.</MenuItem>
                                            </Select>
                                            <FormHelperText>Genre</FormHelperText>
                                        </FormControl>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={2}>
                                        <FormControl
                                            fullWidth
                                            className={selectClasses}>
                                            <Select
                                                labelId="category"
                                                id="category"
                                                name="category.code"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="PRIVATE">Particulier</MenuItem>
                                                <MenuItem value="PRO">Pro</MenuItem>
                                                <MenuItem value="COMPANY">Sociétés</MenuItem>
                                                <MenuItem value="HOTEL">Hôtels</MenuItem>
                                                <MenuItem value="CPAM">CPAM</MenuItem>
                                                <MenuItem value="VIP">VIP</MenuItem>
                                                <MenuItem value="BLACKLIST">Blacklist</MenuItem>
                                            </Select>
                                            <FormHelperText>Type</FormHelperText>
                                        </FormControl>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            id="lastName"
                                            helperText="Nom"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"lastName",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            id="firstName"
                                            helperText="Prénom"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"firstName",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            id="company"
                                            helperText="Société"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"company",
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id="privateEmail"
                                            helperText="Email privé"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"privateEmail",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id="workEmail"
                                            helperText="Email Pro"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"workEmail",
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id="mobilePhoneNumber"
                                            helperText="Téléphone portable"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"mobilePhoneNumber",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id="workMobilePhoneNumber"
                                            helperText="Téléphone portable Pro"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"workMobilePhoneNumber",
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            id="homePhoneNumber"
                                            helperText="Téléphone fixe"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"homePhoneNumber",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            id="workLandlinePhoneNumber"
                                            helperText="Téléphone fixe Pro"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"workLandlinePhoneNumber",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            id="otherPhoneNumber"
                                            helperText="Téléphone (autre)"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"otherPhoneNumber",
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            id="comments"
                                            helperText="Commentaires"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                multiline: true,
                                                rows: 5,
                                                onChange: event => {
                                                    handleChange(event)
                                                },
                                                name:"comments",
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>


                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GooglePlacesAutocomplete
                                            helperText={"Adresse personnelle"}
                                            address={client.homeAddress}
                                            name={"homeAddress"}
                                            onChange={(newAddress) => handleAddressChange(newAddress, "HOME")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GooglePlacesAutocomplete
                                            helperText={"Adresse professionnelle"}
                                            address={client.workAddress}
                                            name={"workAddress"}
                                            onChange={(newAddress) => handleAddressChange(newAddress, "WORK")} />
                                    </GridItem>
                                </GridContainer>
                                <Button color="rose" className={classes.updateProfileButton} type="submit">
                                    Valider
                                </Button>
                                <Clearfix/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form>
        </div>
    )
}