import React, {Component} from "react";
import {Divider, styled, TablePagination} from "@material-ui/core";
import MaterialTable from "material-table";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import SearchBar from "material-ui-search-bar";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import frLocale from "date-fns/locale/fr";
import Button from "../CustomButtons/Button";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MarkAsPaidIcon from '@material-ui/icons/AccountBalance';

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

class FrLocalizedUtils extends DateFnsUtils {
    getDateTimePickerHeaderText(date) {
        return format(date, "dd/MM", { locale: frLocale });
    }
}

const useStyles = makeStyles(styles);

function Rides(props) {
    const classes = useStyles();
    const columns = [
        { title: 'Numéro', field: 'rideNumber', searchable: false  },
        { title: 'Départ le', field: 'departingAt', searchable: false  },
        { title: 'Adresse de départ', field: 'fromAddress', searchable: false },
        { title: 'Adresse d\'arrivée', field: 'toAddress' , searchable: false },
        { title: 'Client', field: 'clientFullName', searchable: false  },
        { title: 'Statut', field: 'rideStatus', searchable: false  },
        { title: 'Chauffeur', field: 'rideDriver', searchable: false  }
    ];

    return (

        <div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <SearchBar
                                    placeholder="Nom du client, Prénom du client ou Numéro de course"
                                    value={props.searchValue}
                                    onChange={props.onSearchChange}
                                    onCancelSearch={props.cancelSearch}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                        <span style={{float: "right"}}>
                                            <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
                                                <KeyboardDateTimePicker
                                                    clearable
                                                    label="Du"
                                                    value={props.fromDate}
                                                    onChange={(date, value) => props.handleFromDateChange(date, value)}
                                                    format="dd/MM/yyyy HH:mm"
                                                />

                                                <KeyboardDateTimePicker
                                                    clearable
                                                    label="Au"
                                                    value={props.toDate}
                                                    onChange={(date, value) => props.handleToDateChange(date, value)}
                                                    format="dd/MM/yyyy HH:mm"
                                                    style={{marginLeft: 20}}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </span>
                            </GridItem>
                        </GridContainer>
                        <GridContainer style={{marginTop: '20px'}}>
                            <GridItem xs={12} sm={12} md={10}>
                                {props.drivers !== undefined ? <Select
                                    labelId="driver"
                                    id="driver"
                                    name="driver"
                                    value={props.selectedDriverIdInSearch}
                                    onChange={(event) => props.onDriverSelectedChange(event)}
                                >
                                    <MenuItem key={0} value={0}>Tous</MenuItem>
                                    {
                                        props.drivers.map(driver => {
                                            return <MenuItem key={driver.id} value={driver.id}>{driver.firstName + " " + driver.lastName}</MenuItem>
                                        })
                                    }
                                </Select> : ""}

                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <Button color="rose" className={classes.updateProfileButton} onClick={(event) => props.filterRides(event)}>
                                    Filtrer
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        <MaterialTable columns={columns}
                       data={props.rides}
                       title='Courses'
                       onRowClick={(event, selectedRow) => {
                           props.history.push({pathname: `/courses/${selectedRow.id}`})
                           event.stopPropagation()
                       }}
                       options={{
                           search: false,
                           pageSize: props.pageSize,
                           sorting: false,
                           actionsColumnIndex: -1,
                           selection: props.actionSelect
                       }}
                       actions={[
                           props.actionDelete ? {
                               icon: 'delete',
                               tooltip: 'Supprimer la course',
                               onClick: (event, selectedRow) => {
                                   props.onDeleteRide(selectedRow.id)
                               }
                           } : ""
                       ]}
                       localization={{
                           pagination: {
                               labelDisplayedRows: '{from}-{to} sur {count}',
                               labelRowsSelect: "lignes"
                           },
                           toolbar: {
                               nRowsSelected: '{0} lignes(s) sélectionnées'
                           },
                           header: {
                               actions: 'Actions'
                           },
                           body: {
                               emptyDataSourceMessage: 'Il n\'y a pas de courses',
                               filterRow: {
                                   filterTooltip: 'Filter'
                               }
                           }
                       }}
                       components={{
                           Pagination: propsPagination => (
                               <TablePagination
                                   {...propsPagination}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   rowsPerPage={props.pageSize}
                                   count={props.totalElements}
                                   page={props.currentPage}
                                   onChangePage={(event, newPageNumber) => props.handlePageChange(event, newPageNumber)}
                                   onChangeRowsPerPage={(event) => props.handleRowsPerPageChange(event)}
                               />
                           )
                       }}
                       onSelectionChange={(rows) => props.handleRowsSelection(rows)}
        />
        </div>
    )
}

export default Rides;