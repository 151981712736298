import Clients from "views/Clients/Clients.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import CreateClient from "./views/Clients/CreateClient";
import Rides from "./views/Rides/Rides";
import CreateOrUpdateRide from "./views/Rides/CreateOrUpdateRide";
import Calls from "./views/Calls/Calls";
import CallsToBeTreatedOrNot from "./views/Calls/CallsToBeTreatedOrNot";

// @material-ui/icons
import MapIcon from '@material-ui/icons/Map';
import ClientsIcon from '@material-ui/icons/SupervisorAccount';
import AddIcon from '@material-ui/icons/Add';
import RidesIcon from '@material-ui/icons/LocalTaxi';
import SeeIcon from '@material-ui/icons/Visibility';
import PhoneIcon from "@material-ui/icons/Phone";
import {AccessTime, Call, CallEnd} from "@material-ui/icons";
import FinishedRidesIcon from '@material-ui/icons/DoneAll';
import InvoicesIcon from '@material-ui/icons/Description';

import Invoices from "./views/Invoices/Invoices";
import FinishedRides from "./views/Rides/FinishedRides";
import RidesToBeInvoiced from "./views/Invoices/RidesToBeInvoiced";

const userRole = localStorage.getItem("role")

const callsRoutes = {
  collapse: true,
  name: "Centre d'appels",
  rtlName: "",
  icon: Call,
  state: "callsCollapse",
  views: [
    {
      path: "/appels/historique",
      name: "Liste des appels",
      rtlName: "خرائط جوجل",
      mini: "A",
      rtlMini: "زم",
      icon: PhoneIcon,
      component: Calls,
      layout: ""
    },
    {
      path: "/appels/a-traiter",
      name: "Appels à traiter",
      rtlName: "خرائط جوجل",
      mini: "AAT",
      rtlMini: "زم",
      icon: AccessTime,
      component: CallsToBeTreatedOrNot,
      layout: ""
    }, {
      path: "/appels/traites",
      name: "Appels traités",
      rtlName: "خرائط جوجل",
      mini: "AT",
      rtlMini: "زم",
      icon: CallEnd,
      component: CallsToBeTreatedOrNot,
      layout: ""
    }
  ]
}

const clientsRoutes = {
      collapse: true,
      name: "Clients",
      rtlName: "خرائط",
      icon: ClientsIcon,
      state: "clientsCollapse",
      views: [
        {
          path: "/clients/creer",
          name: "Créer",
          rtlName: "خريطة كاملة الشاشة",
          mini: "CRE",
          icon: AddIcon,
          rtlMini: "ووم",
          component: CreateClient,
          layout: ""
        },
        {
          path: "/clients/liste",
          name: "Consulter",
          rtlName: "خريطة المتجه",
          mini: "VM",
          rtlMini: "تم",
          icon: SeeIcon,
          component: Clients,
          layout: ""
        }
      ]
    }

const ridesRoutes = {
      collapse: true,
      name: "Courses",
      rtlName: "",
      icon: RidesIcon,
      state: "ridesCollapse",
      views: [
        {
          path: "/courses/creer",
          name: "Créer",
          rtlName: "",
          mini: "CRE",
          rtlMini: "ووم",
          icon: AddIcon,
          component: CreateOrUpdateRide,
          layout: ""
        },
        {
          path: "/courses/liste",
          name: "Consulter",
          rtlName: "",
          mini: "VM",
          icon: SeeIcon,
          rtlMini: "تم",
          component: Rides,
          layout: ""
        },
        {
          path: "/courses/terminees",
          name: "Terminées",
          rtlName: "خريطة المتجه",
          mini: "VM",
          rtlMini: "تم",
          icon: FinishedRidesIcon,
          component: FinishedRides,
          layout: ""
        }
      ]
    }

const mapRoute = {
      path: "/carte",
      name: "Carte",
      rtlName: "خرائط جوجل",
      mini: "GM",
      rtlMini: "زم",
      icon: MapIcon,
      component: GoogleMaps,
      layout: ""
    }

const invoicesRoutes = {
      collapse: true,
      name: "Facturation",
      rtlName: "خرائط",
      icon: InvoicesIcon,
      state: "invoicesCollapse",
      views: [
        {
          path: "/courses-a-facturer",
          name: "Courses à facturer",
          rtlName: "خرائط جوجل",
          mini: "GM",
          rtlMini: "زم",
          icon: MapIcon,
          component: RidesToBeInvoiced,
          layout: ""
        },
          {
              path: "/factures",
              name: "Factures",
              rtlName: "خرائط جوجل",
              mini: "GM",
              rtlMini: "زم",
              icon: MapIcon,
              component: Invoices,
              layout: ""
          }
      ]
    }
const dashRoutes = []

if(userRole !== null) {
    if(userRole.includes('ADMIN') || userRole.includes('CLIENTS')) {
        dashRoutes.push(clientsRoutes)
    }

    if(userRole.includes('ADMIN') || userRole.includes('CALLS')) {
        dashRoutes.push(callsRoutes)
    }

    if(userRole.includes('ADMIN') || userRole.includes('RIDES')) {
        dashRoutes.push(ridesRoutes)
    }

    if(userRole.includes('ADMIN') || userRole.includes('MAP')) {
        dashRoutes.push(mapRoute)
    }

    if(userRole.includes('ADMIN') || userRole.includes('INVOICES')) {
        dashRoutes.push(invoicesRoutes)
    }

}

export default dashRoutes;
