import React, {useState} from "react";
import {useHistory} from 'react-router-dom';

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import login from "assets/img/login-background.jpg";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2)
const useAlertStyles = makeStyles(alertStyles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [alert, setAlert] = useState(null)

  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });


  const history = useHistory();
  function handleSubmit(event) {
    event.preventDefault()

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"))

    fetch('https://api.taximoove.fr/users/authenticate', {
      credentials: 'include',
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        email: event.target.email.value,
        password: event.target.password.value
      })
    })
    .then(result => result.json())
    .then(jsonResult => {
      if(jsonResult.code !== undefined) {
        errorAlert(jsonResult.message)
      } else {
        localStorage.setItem("groupementId", jsonResult.groupement.id)
        localStorage.setItem("groupementName", jsonResult.groupement.name)
        localStorage.setItem("groupementLogo", jsonResult.groupement.logoUrl)
        localStorage.setItem("groupementType", jsonResult.groupement.type)
        localStorage.setItem("groupementPhoneNumber", jsonResult.groupement.phoneNumber)
        localStorage.setItem("token", jsonResult.jwt)
        localStorage.setItem("firstName", jsonResult.firstName)
        localStorage.setItem("lastName", jsonResult.lastName)
        localStorage.setItem("avatarUrl", jsonResult.avatarUrl)
        localStorage.setItem("role", jsonResult.role)
        localStorage.setItem("firstLogin", "yes")

        history.push("/");
      }
    })
    .catch(error => console.log("Error : " + error))

  }

  const classes = useStyles();
  const classes2 = useStyles2();
  const wrapper = React.createRef();

  const alertClasses = useAlertStyles();

  const hideAlert = () => {
    setAlert(null)
  }
  const errorAlert = (errorMessage) => {
    setAlert(
        <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Erreur !"
            onConfirm={() => {
              hideAlert()
            }}
            onCancel={() => {
              hideAlert()
            }}
            confirmBtnCssClass={alertClasses.button + " " + alertClasses.danger}
        >
          {errorMessage}
        </SweetAlert>
    )
  }

  return (
      <div>
        {alert}
        <div className={classes2.wrapper} ref={wrapper}>
          <div
              className={classes2.fullPage}
              style={{ backgroundImage: "url(" + login + ")" }}
          >
            <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Connexion</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  name="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Mot de passe"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  name="password"
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" block type="submit">
                  Valider
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
          </div>
        </div>
      </div>
  );
}
